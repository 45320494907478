import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import Battle from "./components/Battle";
import BuyTokens from "./components/BuyTokens";

import SubmitNFT from "./components/SubmitNFT";
import SubmitNFT2 from "./components/SubmitNFT2";

import Navbar from "./components/Navbar";
import { AppProvider, useAppContext } from "./context/AppContext";
import BattleResult from "./components/BattleResult";
import MyVotes from "./components/MyVotes";
import MyCreatedBattles from "./components/MyCreatedBattles";
import NftRanking from "./components/NFTRanking";
import Faq from "./components/FAQ";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

function Root() {
  return (
    <AppProvider>
      <App />
    </AppProvider>
  );
}

function App() {
  const { ready } = useAppContext();
  // const location = useLocation();

  // useEffect(() => {
  //   const analytics = getAnalytics();
  //   logEvent(analytics, 'screen_view', {
  //     firebase_screen: location.pathname, 
  //     firebase_screen_class: location.pathname,
  //     page_path: location.pathname,
  //     search: location.search
  //   });
  // }, [location]);

  if (!ready) {
    return <div>loading </div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Battle />}></Route>
          <Route path="/battle" element={<Battle />}></Route>
          <Route path="/submitnft" element={<SubmitNFT />}></Route>
          <Route path="/submitnft2" element={<SubmitNFT2 />}></Route>

          <Route path="/buytokens" element={<BuyTokens />}></Route>
          <Route path="/stats" element={<BattleResult />}></Route>
          <Route path="/myvotes" element={<MyVotes />}></Route>
          <Route path="/mybattles" element={<MyCreatedBattles />}></Route>
          <Route path="/nftranking" element={<NftRanking />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Root;
