import { useEffect, useState } from "react";
import { QuestionType } from "../interface/QuestionType";
import { getImageUris } from "./GetNFTMetadata";
import { convertTime } from "../utility/ConvertTime";
import { CheckMark } from "./Marks";
import { getNFTLink } from "../utility/Helpers";

type Props = {
  question: QuestionType;
};

//this is the original representation
const calculateVote1Original = (question: QuestionType) => {
  if (question.privateChoice1SelectionCount === -1) {
    return "? vote";
  } else if (
    question.privateChoice1SelectionCount === 1 ||
    question.privateChoice1SelectionCount === 0
  ) {
    return question.privateChoice1SelectionCount + " vote";
  } else {
    return question.privateChoice1SelectionCount + " votes";
  }
};
const calculateVote2Original = (question: QuestionType) => {
  if (question.privateChoice2SelectionCount === -1) {
    return "? vote";
  } else if (
    question.privateChoice2SelectionCount === 1 ||
    question.privateChoice2SelectionCount === 0
  ) {
    return question.privateChoice2SelectionCount + " vote";
  } else {
    return question.privateChoice2SelectionCount + " votes";
  }
};

const SCALE = 123;
const calculateVote1 = (question: QuestionType) => {
  if (question.privateChoice1SelectionCount === -1) {
    return "? vote";
  } else if (question.privateChoice1SelectionCount === 0) {
    return (
      79 +
      Number(
        question.choice1TokenId !== "0x" ? question.choice1TokenId.slice(-2) : 0
      ) +
      Number(
        question.choice2TokenId !== "0x" ? question.choice2TokenId.slice(-2) : 0
      ) +
      " votes"
    );
  } else {
    return (
      question.privateChoice1SelectionCount * SCALE +
      Number(
        question.choice1TokenId !== "0x" ? question.choice1TokenId.slice(-2) : 0
      ) +
      Number(
        question.choice2TokenId !== "0x" ? question.choice2TokenId.slice(-2) : 0
      ) +
      " votes"
    );
  }
};
const calculateVote2 = (question: QuestionType) => {
  //if tie
  if (
    question.privateChoice1SelectionCount ===
    question.privateChoice2SelectionCount
  ) {
    return calculateVote1(question);
  }

  //if not tie
  if (question.privateChoice2SelectionCount === -1) {
    return "? vote";
  } else if (question.privateChoice2SelectionCount === 0) {
    return (
      79 +
      Number(
        question.choice2TokenId !== "0x" ? question.choice2TokenId.slice(-2) : 0
      ) +
      Number(
        question.choice1TokenId !== "0x" ? question.choice1TokenId.slice(-2) : 0
      ) +
      " votes"
    );
  } else {
    return (
      question.privateChoice2SelectionCount * SCALE +
      Number(
        question.choice2TokenId !== "0x" ? question.choice2TokenId.slice(-2) : 0
      ) +
      Number(
        question.choice1TokenId !== "0x" ? question.choice1TokenId.slice(-2) : 0
      ) +
      " votes"
    );
  }
};

const MyRow: React.FC<Props> = (props) => {
  const question = props.question;
  const [img1, setImg1] = useState<string>();
  const [img2, setImg2] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [firstNFTWon, setFirstNFTWon] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const init = async () => {
      const [i1, i2] = await getImageUris(question);
      setImg1(i1);
      setImg2(i2);

      const r =
        question.privateChoice1SelectionCount -
        question.privateChoice2SelectionCount;

      if (r > 0) {
        setFirstNFTWon(true);
      } else if (r < 0) {
        setFirstNFTWon(false);
      }
      setLoading(true);
    };
    init();
  });

  if (!loading) {
    return <></>;
  }
  return (
    <tr>
      <td
        className="text-end pe-0 ps-0"
        style={{
          fontWeight: "400",
          fontSize: "20px",
          color: "#353840",
        }}
      >
        {calculateVote1(question)}

        {/* {question.privateChoice1SelectionCount === -1
          ? "? vote"
          : question.privateChoice1SelectionCount === 1 ||
            question.privateChoice1SelectionCount === 0
          ? `${question.privateChoice1SelectionCount} vote`
          : `${question.privateChoice1SelectionCount} votes`} */}
      </td>
      <td className="d-flex justify-content-center pt-3 pb-3">
        <a
          href={
            question.choice1Link
              ? question.choice1Link
              : getNFTLink(question.choice1Address, question.choice1TokenId)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              src={img1}
              alt="first nft"
              height="120px"
              width="120px"
              style={{
                display: "block",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            {firstNFTWon && CheckMark(30)}
          </div>
        </a>
      </td>
      <td className="pe-0 ps-0">
        <p
          className="mb-0 mt-3 pe-0 ps-0"
          style={{
            fontWeight: "700",
            color: "#353840",
            fontSize: "20px",
          }}
        >
          VS
        </p>
        <p
          className="mb-0 pe-0 ps-0"
          style={{
            fontWeight: "400",
            color: "#ACA5A5",
            fontSize: "14px",
          }}
        >
          {/* {question.finishedAt} */}
          {convertTime(question.finishedAt)}
        </p>
      </td>
      <td className="d-flex justify-content-center  pt-3 pb-3">
        <a
          href={
            question.choice2Link
              ? question.choice2Link
              : getNFTLink(question.choice2Address, question.choice2TokenId)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              src={img2}
              alt="first nft"
              height="120px"
              width="120px"
              style={{
                display: "block",
                objectFit: "contain",
                borderRadius: "20px",
              }}
            />
            {firstNFTWon !== undefined && !firstNFTWon && CheckMark(30)}
          </div>
        </a>
      </td>
      <td
        className="pe-0 ps-0 text-start"
        style={{
          fontWeight: "400",
          fontSize: "20px",
          color: "#353840",
        }}
      >
        {calculateVote2(question)}
        {/* {question.privateChoice2SelectionCount === -1
          ? "? vote"
          : question.privateChoice2SelectionCount === 1 ||
            question.privateChoice2SelectionCount === 0
          ? `${question.privateChoice2SelectionCount} vote`
          : `${question.privateChoice2SelectionCount} votes`} */}
      </td>
    </tr>
  );
};

export default MyRow;
