import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { QuestionType } from "../interface/QuestionType";
import DummySlides from "../utility/DummySlides";
import LoadingView from "../utility/LoadingView";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import BattleSlide from "../utility/BattleSlide";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAppContext } from "../context/AppContext";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

import { getAnalytics, logEvent } from "firebase/analytics";
import { isMobile } from "react-device-detect";

export default function Battle() {
  const functions = getFunctions();
  const viewBattles = httpsCallable(functions, "viewBattles");

  const [questionsToView, setQuestionsToView] = useState<Array<QuestionType>>(
    []
  );
  const [loaded, setLoaded] = useState(false);
  const [updateAt, setUpdateAt] = useState(1);
  const [nextUpdateAt, setNextUpdateAt] = useState(1);

  const { authUser } = useAppContext();

  //useEffect() to fetch data from viewBattles()
  //and set questionsToView.
  useEffect(() => {
    setLoaded(false);
    const init = async () => {
      try {
        //TODO: this part probably needs to be rewritten for better type checking
        //also, copying the array looks strange.
        const q = await viewBattles({ updateAt: updateAt });
        const [x, z]: any = q.data;
        const y: Array<QuestionType> = [];
        for (let i = 0; i < x.length; i++) {
          y.push(x[i].data);
        }
        setQuestionsToView(y);
        setNextUpdateAt(z);

        setLoaded(true);
        logEvent(getAnalytics(), 'viewBattles', { battles_displayed: y.length });
      } catch (err) {
        console.log(err);
      }
    };
    if (authUser) {
      init();
    }
  }, [updateAt, authUser]);

  const questionView = (
    <div className="container">
      <div>
        {!isMobile && (
          <h1
            className="text-center"
            style={{ fontWeight: "700", fontSize: "64px", color: "#353840" }}
          >
            Which NFT is Better?
          </h1>
        )}
        {isMobile && (
          <div>
            <hr></hr>
            <h1
              className="text-center"
              style={{ fontWeight: "700", fontSize: "40px", color: "#353840" }}
            >
              Which NFT is Better?
            </h1>
          </div>
        )}
      </div>

      <Swiper
        navigation={true}
        allowTouchMove={false}
        modules={[Pagination, Navigation]}
      >
        {questionsToView.map((question) => (
          <SwiperSlide key={question.uid}>
            <BattleSlide
              allQuestions={questionsToView}
              question={question}
              setQuestionsToView={setQuestionsToView}
              nextUpdateAt={nextUpdateAt}
              setUpdateAt={setUpdateAt}
            ></BattleSlide>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  const noQuestionView = (
    <div className="container ">
      <div className="text-center">
        <span style={{ fontWeight: "700", fontSize: "36px", color: "#353840" }}>
          End of Questions!{" "}
        </span>
        <br></br>
        <br></br>
        <img
          src={"imgs/Azuki_numbet2157.png"}
          className="card-img-top"
          alt="first nft"
          height="200px"
          width="200px"
          style={{
            objectFit: "cover",
            borderRadius: "20px",
          }}
        />
        <br></br>

        <br></br>

        <p style={{ fontWeight: "700", fontSize: "36px", color: "#353840" }}>
          Come back later or {/* <Nav.Link to="/submitnft" as={NavLink}> */}
          <a href="/submitnft2">Create Duels!</a>
          {/* </Nav.Link> */}
        </p>
      </div>
    </div>
  );

  const view = () => {
    if (!authUser) {
      return <DummySlides />;
    }

    if (!loaded) {
      return <LoadingView loadingItem="NFT Duels" />;
    } else if (questionsToView.length === 0) {
      return noQuestionView;
    } else {
      return (
        <div>
          {questionView}

          <br></br>
        </div>
      );
    }
  };

  return <div style={{ minHeight: "100vh" }}>{view()}</div>;
}
