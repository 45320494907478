type Props = {
  loadingItem: string;
};

const LoadingView: React.FC<Props> = (props) => {
  return (
    <div className="d-flex flex-column">
      <div className="spinner-border text-primary mx-auto" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <br></br>
      <h3 className="mx-auto">&nbsp;Loading {props.loadingItem} ...</h3>
    </div>
  );
};

export default LoadingView;
