import { useEffect, useState } from "react";
import Web3 from "web3";
import { BATTLETOKEN_ABI } from "../ERC20ABI";
import { AbiItem } from "web3-utils";
import { Contract } from "web3-eth-contract";
import { useAppContext } from "../context/AppContext";
import { Accordion, Nav } from "react-bootstrap";

function BuyTokens() {
  const [web3, setWeb3] = useState();
  const [contract, setContract] = useState<Contract>();
  const [account, setAccount] = useState<string>();
  const { isLoading, authUser } = useAppContext();

  useEffect(() => {
    const init = async () => {
      const web3 = new Web3(
        new Web3.providers.HttpProvider("http://localhost:7545")
      );

      // const battleTokenAbi = await getKAbi();
      // const battleTokenK = await getKAddress();
      // const k = new web3.eth.Contract(
      //   battleTokenAbi.abi, //BATTLETOKEN_ABI as AbiItem[],
      //   battleTokenK.contractAddress //"0xEd49E5a0FA4263DdaE0EE357eCfC86Ca16c310a9"
      // );
      // setContract(k);

      // const accounts = await web3.eth.getAccounts();
      // const account = accounts[0];
      // setAccount(account);

      // const events = await k.getPastEvents("allEvents");
      // console.log(events);
    };
    init();
  }, []);

  async function getKAddress() {
    const res = await fetch(
      `http://localhost:5001/w3nftbattle/us-central1/getContractAddress/
    `,
      { method: "GET" }
    );
    const r = await res.json();
    console.log(r);
    return r;
  }

  async function getKAbi() {
    const res = await fetch(
      `http://localhost:5001/w3nftbattle/us-central1/getContractAbi/
    `,
      { method: "GET" }
    );
    const r = await res.json();
    console.log(r);
    return r;
  }

  async function handleBuyTokens() {
    if (contract) {
      //buy tokens
      await contract.methods
        .buyBattleTokens()
        .send({ from: account, value: "1000000000000000000" })
        .on("receipt", (hash: string) => {
          console.log("successfully purchased nft duels tokens");
          console.log("transaction hash is ", hash);
        });

      await contract.methods
        .depositBattleTokens(1000)
        .send({ from: account })
        .on("receipt", (hash: any) => {
          console.log("successfully deposited 1000 nft duels tokens");
          console.log("transaction hash is ", hash);
        });

      const e = await contract.getPastEvents("Transfer", {
        fromBlock: 0,
        toBlock: "latest",
      });
      console.log(e[0].blockHash);
    }
  }

  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <div className="row">
        <div className="col-lg-3"></div>

        <div className="col-lg-6">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h5 style={{ fontWeight: "700" }}>
                  What is NFT Duels Token (NDT)?
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                NFT Duels Token (NDT) is the native token for NFT Duels. It has
                been preminted on the BNB chain. BScScan Info{" "}
                <a
                  href="https://bscscan.com/address/0x1a94B30C5531cfb839983c422B2AD483f9211449
"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
                <br />
                <br />
                NDTs are used for creating NFT Duels (costing 10 NDTs in beta
                test) and for voting in NFT Duels (costing 1 NDT in beta test).
                <br />
                <br />
                In the future, NDTs can be used to vote on new proposals for NFT
                Duels. Similar to how Compound owners can direct the course of
                Compound development.
                <br />
                <br />
                In our public beta, each wallet logging into the NFT Duels app
                will be automatically credited 100 NDTs. These 100 NDTs can be
                used to create new NFT Duels or vot in existing NFT duels.
                <br />
                <br />
                You can earn additional NDTs by winning in NFT Duels (that is,
                voting for the NFT that eventually receives mroe votes).
                <br />
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>Did I just get 100 NDTs?</h5>
              </Accordion.Header>
              <Accordion.Body>
                During beta test, every new wallet that logs in to NFT Duels is
                automatically credited with 100 NDTs. You can use these 100 NDTs
                to create 10 NFT Duels with your choice of NFT, or vote in 100
                existing NFT Duels.
                <br />
                <br />
                If you win in an NFT Duel (your picked NFT has more votes when
                the NFT Duel ends), you will be rewarded with a number of NFT
                Duels tokens. <br />
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>
                  How do I withdraw my NDTs{" "}
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                During beta test, users are not yet able to withdraw NDTs or
                purchase additional NDTs. When beta test ends, this page will
                have functions that allows users to withdraw and purchase NDTs.
                <br />
                <br />
                The balance of your NDTs is shown on the top right corner (if
                you are on mobile, click on the collapse icon). This is the
                amount you wil be able to withdraw when beta test ends.
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>
                  How will NDTs be allocated?
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                1,000,000,000 NDTs have been preminted on BNB chain now. BScScan
                Info{" "}
                <a
                  href="https://bscscan.com/address/0x1a94B30C5531cfb839983c422B2AD483f9211449
"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                . We plan to distribute them in the following way:
                <br />
                <br />
                - 60% of NDTs (600,000,000 NDTs) will be distributed to NFT
                Duels community members, which includes those that will be
                distributed by the end of the beta test. In the very unlikely
                event that more than 600,000,000 NDTs are claimed by the end of
                the beta test, we will distribute all the 600,000,000
                proportinally.
                <br />
                <br />
                - 20% Of NDTs (200,000,000 NDTs) will be distributed to NFT
                Duels team members and future employees, with a 4-year vesting.
                <br />
                <br />
                - 18% Of NDTs (180,000,000 NDTs) will be distributed to NFT
                Duels investors, with a 4-year vesting.
                <br />
                <br />
                - 2% Of NDTs (20,000,000 NDTs) will be distributed to NFT Duels
                advisors, with a 4-year vesting.
                <br />
                <br />
                A proposed inflation rate Of 2% will start after 4 years to
                ensure continued pariticpation.
                <br />
                <br />
                You may notice the tokenomics is very similar to that of UNI,
                and it is intentioanlly designed so. We admire that Uniswap has
                introduced Decentralized Exchange to the web3 world, and hope
                NFT Duels will do the same for the NFT world. Specifically, we
                hope to democratize and decentralize the valuation of NFTs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>
                  How do I claim my NDTs now?
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                You will see your NDT balance at the top right corner (click on
                the collapse icon if you are on mobile). Please note, however,
                you will not be able to claim these NDTs (e.g., sending them to
                your wallet) before the beta test is over. You will not be able
                to purchase additional NDTs during beta test either.
                <br />
                <br />
                When the beta test is over, you can come back to this page and
                there will be a button that allows the withdrawing of your NDTs,
                by which we will send NDTs to your wallet.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>
                  How can I purchase more NDTs?
                </h5>
              </Accordion.Header>
              <Accordion.Body>
                You can not purchase NDTs during beta test. If you need more
                NDTs, try to win more by voting in existing NFT Duels, or loggin
                in with a fresh wallet.
                <br />
                <br />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {" "}
                <h5 style={{ fontWeight: "700" }}>Why BNB Chain?</h5>
              </Accordion.Header>
              <Accordion.Body>
                We choose to release NDTs on BNB chain because of its fast
                developing ecosystem and the low gas fee.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <img
            src="imgs/Lets_Walk_numbet067.webp"
            alt="img"
            width={"200"}
          ></img>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </div>
  );
}

export default BuyTokens;
