import React, { useRef, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { IERC721MetadataABI, ERC1155MetadataURI } from "../utility/Web3";
import { AbiItem } from "web3-utils";
import Web3 from "web3";

import "react-toastify/dist/ReactToastify.css";

// import axios from "axios";
// import Web3 from "web3";

function SubmitNFT() {
  const [nftAddress, setNftAddress] = useState("");
  const [nftName, setNftName] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [tokenId2, setTokenId2] = useState("");
  const [tokenStandard, setTokenStandard] = useState("ERC721");

  const [battleNumber, setBattleNumber] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [nftConfirmed, setNFTConfirmed] = useState(false);
  // const [imageUrl, setImageUrl] = useState("/imgs/Cool_Cat_numbet6933.png");
  const [imageUrl, setImageUrl] = useState("");

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const { web3, account, chainId } = useAppContext();

  const functions = getFunctions();
  const submitNFT = httpsCallable(functions, "submitNFT");
  const submitNFT2 = httpsCallable(functions, "submitNFT2");

  const GATEWAY = "https://gateway.pinata.cloud/ipfs/";
  //"https://tachyon.mypinata.cloud/ipfs/"

  async function handleClick2() {}

  async function handleClick() {
    if (nftAddress === "" || tokenId === "" || battleNumber === "") {
      alert("input missing!");
      return;
    }
    try {
      // notify();
      await submitNFT({
        nftAddress: nftAddress,
        tokenId: tokenId,
        battleNumber: battleNumber,
      });

      alert(
        "Your NFT is successfully submitted!\nGo to 'Results' -> 'My NFTs' to see your duels"
      );
      setNftAddress("");
      setTokenId("");
      setBattleNumber("");
      setNFTConfirmed(false);
      setNftName("");
      setTokenId2("");
      setImageUrl("");
    } catch (e) {
      alert(e);
      setNftAddress("");
      setTokenId("");
      setBattleNumber("");
      setNFTConfirmed(false);
      setNftName("");
      setTokenId2("");
      setImageUrl("");
    }
  }

  const generatePreview = async () => {
    setIsConfirming(true);
    try {
      //get the nft from web3

      let nft;
      if (tokenStandard === "ERC721") {
        nft = new web3.eth.Contract(
          IERC721MetadataABI as AbiItem[],
          nftAddress
        );
        //get the nft name from web3
        const nftName: string = await nft.methods.name().call();
        setNftName(nftName);
        console.log(nftName);
        setTokenId2(tokenId);

        //get the nft token uri from web3
        const nftUri: string = await nft.methods.tokenURI(tokenId).call();
        //get the nft metadata with nft token uri and using to our GATEWAY for serving the file
        const nftMetadataRaw = await fetch(nftUri.replace("ipfs://", GATEWAY));
        const nftMetadata = await nftMetadataRaw.json();
        //get the nft image IPFS uri from the nft metadata, changing to our GATEWAY
        const nftImageUri = nftMetadata.image.replace("ipfs://", GATEWAY);
        //get the image
        const nftImageRaw = await fetch(nftImageUri);
        //Get the image blob
        const nftImage = await nftImageRaw.blob();
        //Create our own URL
        setImageUrl(URL.createObjectURL(nftImage));
        URL.revokeObjectURL(imageUrl);
        setNFTConfirmed(true);
      } else {
        //erc1155
        nft = new web3.eth.Contract(
          ERC1155MetadataURI as AbiItem[],
          nftAddress
        );

        const nftUri: string = await nft.methods.uri(Number(tokenId)).call();
        const nftMetadataRaw = await fetch(
          nftUri.replace("ipfs://ipfs/", GATEWAY) //note this is different from erc721, for some reasons i see most erc1155 uri as "ipfs://ipfs ...."
        );
        const nftMetadata = await nftMetadataRaw.json();
        const nftName: string = nftMetadata.name;
        const nftImageUri = nftMetadata.image.replace("ipfs://ipfs", GATEWAY); //again, this is different from in erc721
        const nftImageRaw = await fetch(nftImageUri);
        const nftImage = await nftImageRaw.blob();
        //Create our own URL
        setImageUrl(URL.createObjectURL(nftImage));
        URL.revokeObjectURL(imageUrl);
        setNFTConfirmed(true);
        setNftName(nftName);
        setTokenId2(" "); //looks like for erc1155, the name shoudl have token id
      }
    } catch (err) {
      console.log(err);
      alert("This NFT cannot be found. Try another one :(");

      setNFTConfirmed(false);
      setImageUrl("");
    }
    setIsConfirming(false);
  };

  function clear() {
    setNftAddress("");
    setNftName("");
    setImageUrl("/imgs/Cool_Cat_numbet6933.png");
    setBattleNumber("");
    setTokenId("");
    setNFTConfirmed(false);
    setIsConfirming(false);
  }

  function PreviewButton() {
    if (nftAddress !== "" && tokenId !== "" && battleNumber !== "") {
      return (
        <button
          disabled={isConfirming}
          type="button"
          className="btn btn-primary btn-block btn-lg"
          onClick={generatePreview}
        >
          {isConfirming && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          Preview NFT
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-primary btn-block btn-lg"
        >
          {isConfirming && (
            <span className="spinner-grow spinner-grow-sm"></span>
          )}
          Preview NFT
        </button>
      );
    }
  }

  function SubmitButton() {
    if (nftAddress !== "" && tokenId !== "" && battleNumber !== "") {
      return (
        <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          {isSubmitting && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          Submit NFT
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          {isSubmitting && (
            <span className="spinner-grow spinner-grow-sm"></span>
          )}
          Submit NFT
        </button>
      );
    }
  }

  const [newImage, setNewImage] = useState();
  async function getOpenSea() {
    //address: string, tokenId: string) {
    const address = "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d";
    const tokenId = "2045";
    const res = await fetch(
      "https://opensea.io/assets/ethereum/0xef4fc659254954d83597a33c68c47cc7d9fd24a4/2507"
      //`https://api.opensea.io/api/v1/asset/${address}/${tokenId}/`
    );
    //const res2 = await res.json();
    // setNewImage(res2.image_url);
    const res2 = await res.text();

    console.log(res2);
  }

  return (
    <div className="container" style={{ minHeight: "80vh" }}>
      <form onSubmit={getOpenSea}>
        <div className="btn btn-primary" onClick={getOpenSea}>
          Submit
        </div>
        {newImage && (
          <img
            className="card-img-top"
            src={newImage} //{{ uri: imageUrl }} //"/imgs/Cool_Cat_numbet6933.png"
            alt="img"
            height="380px"
            // width="300px"
            style={{
              objectFit: "cover",
              borderRadius: "20px",
            }}
          ></img>
        )}
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            NFT Link (Token, not Collection)
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
          />
          <div id="emailHelp" className="form-text">
            Submit your NFT Link to create new NFT Duels. Supports Ethereum and
            Solana NFTs.
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      <h1
        className="text-center"
        style={{ fontWeight: "700", color: "#353840" }}
      >
        Submit Your NFT for Duels
      </h1>
      <div className="container">
        <br></br>
        <div className="row">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-4 col-md-5">
            <form onSubmit={handleSubmit(handleClick)}>
              <div>
                <label className="float-start">
                  <b>Token Standard</b>
                </label>
              </div>
              <br></br>
              <div>
                <select
                  className="form-select-lg mb-4"
                  style={{
                    fontSize: "5",
                    borderColor: "#dde1e5",
                    borderRadius: 5,
                  }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    setTokenStandard(e.target.value);
                  }}
                >
                  <option selected>ERC721&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                  {/* <option value ERC1155">ERC1155</option> */}
                </select>
              </div>

              <div>
                <label className="float-start">
                  <b>NFT Address</b>
                </label>
              </div>

              <div className="input-group mb-4">
                <input
                  type="text"
                  value={nftAddress}
                  onChange={(e) => {
                    setNftAddress(e.target.value);
                    setNFTConfirmed(false);
                    setNftName("");
                    setImageUrl("");
                  }}
                  className="form-control form-control-lg"
                  placeholder="0x1234564234"
                  required
                  style={{ borderRadius: 5 }}
                ></input>
                <br />
              </div>

              <div>
                <label className="float-start">
                  <b> NFT Token ID</b>
                </label>
              </div>
              <div className="input-group mb-4">
                <input
                  type="text"
                  value={tokenId}
                  onChange={(e) => {
                    setTokenId(e.target.value);
                    setNFTConfirmed(false);
                    setNftName("");
                    setImageUrl("");
                  }}
                  className="form-control form-control-lg"
                  placeholder="123"
                  style={{ borderRadius: 5 }}
                  required
                ></input>
                <br />
              </div>

              <div>
                <label className="float-start">
                  <b>How Many Duels? (Costs 10 NDTs each duel)</b>
                </label>
              </div>

              <div className="input-group mb-4">
                <input
                  type="text"
                  value={battleNumber}
                  onChange={(e) => {
                    setBattleNumber(e.target.value);
                    // setNFTConfirmed(false);
                    // setNftName("");
                    // setImageUrl("");
                  }}
                  style={{ borderRadius: 5 }}
                  className="form-control form-control-lg"
                  placeholder="1"
                  required
                ></input>
                <br />
              </div>

              <div className="d-flex justify-content-center">
                {nftConfirmed && <SubmitButton />}
                {!nftConfirmed && <PreviewButton />}
              </div>
            </form>
          </div>
          <div className="col-lg-4 col-md-5">
            {isConfirming ? (
              <div className="d-flex flex-column align-items-center">
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <br></br>
                <h3 className="mx-auto">&nbsp;Loading NFT Image...</h3>
              </div>
            ) : imageUrl ? (
              <div className="card h-100 w-100 border-0">
                <img
                  className="card-img-top"
                  src={imageUrl} //{{ uri: imageUrl }} //"/imgs/Cool_Cat_numbet6933.png"
                  alt="img"
                  height="380px"
                  // width="300px"
                  style={{
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                ></img>
                <div className="card-body">
                  <h5 className="card-title text-center">
                    {nftName ? `${nftName} #${tokenId2}` : ""}
                  </h5>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="col-lg-2 col-md-1"></div>
        </div>
        <br></br>
      </div>
      {/* <hr /> */}
      {/* <div className="container d-flex flex-column justify-content-center mx-5">
        <h3>Here are some NFT addresses for testing</h3>
        <div>ape: 0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d</div>
        <div>
          invisible frineds: 0x59468516a8259058bad1ca5f8f4bff190d30e066 5000
        </div>
        <div>
          chromie squiggle: 0x059edd72cd353df5106d2b9cc5ab83a52287ac3a 9700
        </div>
        <div>
          junk food part: 0xe7868d575cc12917053e767d7fe07c2e4859739f 1108
        </div>
        <div>Looki: 0xffc1131dda0299b804c97c436bc8cfea019e00a0 2302</div>
        <div>cool cat: 0x1a92f7381b9f03921564a437210bb9396471050c</div>
        <div>mayc: 0x60e4d786628fea6478f785a6d7e704777c86a7c6</div>
        <div>cloneX: 0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b</div>
        <div>doodle: 0x8a90cab2b38dba80c64b7734e58ee1db38b8992e</div>
      </div> */}
    </div>
  );
}

export default SubmitNFT;
