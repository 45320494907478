import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { QuestionType } from "../interface/QuestionType";
import { AnswerType } from "../interface/AnswerType";
import MyRowReward from "../utility/MyVoteRow";
import { BsCheckCircleFill, BsPauseFill } from "react-icons/bs";
import { BiSquareRounded } from "react-icons/bi";
import { BsXCircleFill, BsPauseCircleFill } from "react-icons/bs";

import { useAppContext } from "../context/AppContext";
import LoadingView from "../utility/LoadingView";

import { isMobile } from "react-device-detect";
//Question: should we use cache so that this page doesn't referesh each time?
function MyVotes() {
  const functions = getFunctions();
  //Question: what's a good way to set its type?
  //return questionType[]
  const getMyVoteResults = httpsCallable(functions, "getMyVoteResults");

  //this is for refreshing the page when user logs out
  const { authUser } = useAppContext();

  //battle data

  const [answerResult, setAnswerResult] = useState<AnswerType[]>([]);
  const [questionResult, setQuestionResult] = useState<QuestionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [lastTimestamp, setLastTimestamp] = useState<number>();
  const [bottom, setBottom] = useState(false);

  const getData = async () => {
    const timestamp =
      answerResult.length === 0
        ? Date.now()
        : answerResult[answerResult.length - 1].createdAt;

    setLoading(true);
    if (timestamp !== lastTimestamp) {
      const r: any = await getMyVoteResults({
        number: 5, //this needs to be large enough to allow scrolling
        lastTimestamp: timestamp,
      });
      const ar2 = r.data.answers;
      const ar3: Array<AnswerType> = [];

      for (let i = 0; i < ar2.length; i++) {
        ar3.push(ar2[i].data);
      }
      setAnswerResult([...answerResult, ...ar3]);

      const qr2 = r.data.questions;
      const qr3: Array<QuestionType> = [];
      for (let i = 0; i < qr2.length; i++) {
        qr3.push(qr2[i].data);
      }
      if (qr2.length === 0) {
        setBottom(true);
      }
      setQuestionResult([...questionResult, ...qr3]);

      setLastTimestamp(timestamp);
    }
    setLoading(false);
  };

  //initilaizing data
  useEffect(() => {
    const init = async () => {
      await getData();
    };
    setFirstLoading(true);
    init();
    setFirstLoading(false);
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      {!authUser ? (
        <div className="container">
          <br></br>
          <br></br>
          <div className="text-center">
            <span
              style={{ fontWeight: "700", fontSize: "36px", color: "#353840" }}
            >
              To view battles you voted in,
            </span>
            <br></br>
            <br></br>

            <img
              src={"imgs/Azuki_numbet5049.png"}
              className="card-img-top"
              alt="first nft"
              height="200px"
              width="200px"
              style={{
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
            <br></br>
            <br></br>
            <span
              style={{ fontWeight: "700", fontSize: "36px", color: "#353840" }}
            >
              Please connect to Metamask{" "}
            </span>
          </div>
        </div>
      ) : firstLoading ? (
        <LoadingView loadingItem="My Votes" />
      ) : (
        <div>
          <div
            className={`container d-flex ${
              !isMobile && "justify-content-center"
            }`}
          >
            <div className="col-xs-1 col-sm-2 col-md-3"></div>
            <div className="col-xs-12 col-sm-10 col-md-8 ">
              {isMobile && <hr />}
              <h1
                className="text-center"
                style={{ fontWeight: "700", color: "#353840" }}
              >
                My Votes
              </h1>
              <div className="text-end">
                <BiSquareRounded
                  className="me-1"
                  size={20}
                  color="#2081E2"
                  strokeWidth={2}
                ></BiSquareRounded>
                My Pick
                <BsCheckCircleFill
                  className="me-1 ms-4"
                  size={20}
                  color={"#34C77B"}
                ></BsCheckCircleFill>
                Won
                <BsXCircleFill
                  className="me-1 ms-4"
                  size={20}
                  color={"#E62929"}
                ></BsXCircleFill>{" "}
                Lost
                <BsPauseCircleFill
                  className="me-1 ms-4"
                  size={20}
                  color={"#2081E2"}
                ></BsPauseCircleFill>
                Tie
              </div>

              <table className=" table table-sm table-borderless border-primary align-middle text-center ">
                {questionResult.map((q, i) => {
                  return (
                    <tbody key={q.uid}>
                      {
                        <MyRowReward
                          question={q}
                          answer={answerResult[i]}
                        ></MyRowReward>
                      }
                    </tbody>
                  );
                })}
              </table>
              {loading ? (
                <LoadingView loadingItem="My Votes" />
              ) : bottom ? (
                <>
                  <div className="d-flex justify-content-center">
                    <p className="text-muted"> You reached the Bottom </p>
                  </div>
                  <br></br>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary mx-auto"
                      onClick={getData}
                    >
                      Load More
                    </button>
                  </div>
                  <br></br>
                </>
              )}
            </div>

            <div className="col-xs-1 col-sm-2 col-md-3"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyVotes;
