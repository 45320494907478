import {
  BsCheckCircleFill,
  BsFillCircleFill,
  BsXCircleFill,
  BsPauseFill,
  BsPauseCircleFill,
} from "react-icons/bs";

export const CheckMark = (size: number) => {
  return (
    <>
      <BsFillCircleFill
        size={size}
        style={{
          color: "white",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsFillCircleFill>
      <BsCheckCircleFill
        size={size}
        style={{
          color: "#34C77B",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsCheckCircleFill>
    </>
  );
};

export const EqualMark = (size: number) => {
  return (
    <>
      <BsFillCircleFill
        size={size}
        style={{
          color: "white",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsFillCircleFill>
      <BsPauseCircleFill
        size={size}
        style={{
          color: "#2081E2",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsPauseCircleFill>
    </>
  );
};

export const CrossMark = (size: number) => {
  return (
    <>
      <BsFillCircleFill
        size={size}
        style={{
          color: "white",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsFillCircleFill>
      <BsXCircleFill
        size={size}
        style={{
          color: "#E62929",
          position: "absolute",
          top: "-10",
          right: "-10",
        }}
      ></BsXCircleFill>
    </>
  );
};
