//get the image uris for both 1st and 2nd images

import { NftType } from "../interface/NFTType";
import { QuestionType } from "../interface/QuestionType";

//change this to a dedicated one in the future
const GATEWAY = "https://gateway.pinata.cloud/ipfs/";
//"https://tachyon.mypinata.cloud/ipfs/"

export const getImageUris = async (question: QuestionType) => {
  //this is a default profile pic
  const defaultProfile =
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fadinaiqbl%2Fdefault-icon-meme-pfp%2F&psig=AOvVaw0eddthOLO_M3HIrAwjftQ1&ust=1671999984387000&source=images&cd=vfe&ved=0CA4QjRxqFwoTCODjubqLk_wCFQAAAAAdAAAAABAE";

  let nft1Image: string = defaultProfile;
  let nft2Image: string = defaultProfile;

  //determine 1st image uri
  if (question.choice1ImageUri) {
    //first, try use the imageUri field
    nft1Image = question.choice1ImageUri;
  } else if (
    (question.choice1Uri && question.choice1Uri !== "na_ethereum") ||
    question.choice1Uri !== "na_solana"
  ) {
    //second, if imageUri does not exist, and uri exists
    try {
      const nft1Info = await fetch(
        question.choice1Uri.replace("ipfs://", GATEWAY)
      );
      const nft1InfoJSON = await nft1Info.json();
      nft1Image = nft1InfoJSON.image.replace("ipfs://", GATEWAY);
    } catch (err) {
      console.log(err);
    }
  }

  //determine 2nd image uri
  if (question.choice2ImageUri) {
    //first, try use the imageUri field
    nft2Image = question.choice2ImageUri;
  } else if (
    (question.choice2Uri && question.choice2Uri !== "na_ethereum") ||
    question.choice2Uri !== "na_solana"
  ) {
    //second, if imageUri does not exist, and uri exists
    try {
      const nft2Info = await fetch(
        question.choice2Uri.replace("ipfs://", GATEWAY)
      );
      const nft2InfoJSON = await nft2Info.json();
      nft2Image = nft2InfoJSON.image.replace("ipfs://", GATEWAY);
    } catch (err) {
      console.log(err);
    }
  }

  return [nft1Image, nft2Image];
};

export const getNFTNames = async (question: QuestionType) => {
  let nft1Name = "No Name";
  let nft2Name = "No Name";

  nft1Name = question.choice1FullName
    ? question.choice1FullName
    : question.choice1Name + " #" + question.choice1TokenId;
  nft2Name = question.choice2FullName
    ? question.choice2FullName
    : question.choice2Name + " #" + question.choice2TokenId;
  return [nft1Name, nft2Name];
};

export const getNFTLinks = async (question: QuestionType) => {
  let nft1Link = "";
  let nft2Link = "";

  nft1Link = question.choice1Link
    ? question.choice1Link
    : `https://opensea.io/assets/ethereum/${question.choice1Address}/${question.choice1TokenId}`;
  nft2Link = question.choice2Link
    ? question.choice2Link
    : `https://opensea.io/assets/ethereum/${question.choice2Address}/${question.choice2TokenId}`;
  return [nft1Link, nft2Link];
};

//this only return image now, but can return other information in the future
export const getNFTInfo = async (nft: NftType) => {
  let nftImage =
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fadinaiqbl%2Fdefault-icon-meme-pfp%2F&psig=AOvVaw01w5zVXrMLYAn6a3yr7ZI_&ust=1672000255845000&source=images&cd=vfe&ved=0CA8QjRxqFwoTCICpyLyMk_wCFQAAAAAdAAAAABAE";
  if (nft.imageUri) {
    nftImage = nft.imageUri;
  } else if (nft.uri) {
    const nftInfo = await fetch(nft.uri.replace("ipfs://", GATEWAY));
    const nftInfoJSON = await nftInfo.json();
    nftImage = nftInfoJSON.image.replace("ipfs://", GATEWAY);
  }

  let nftName = "no name";
  if (nft.fullName) {
    nftName = nft.fullName;
  } else {
    nftName = nft.name + " #" + nft.tokenId;
  }
  return [nftImage, nftName];
};
