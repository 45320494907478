import { getAuth, signInWithCustomToken } from "firebase/auth";

import { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { useAppContext } from "../context/AppContext";

import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { NavLink } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

interface NonceResponse {
  nonce: string;
}

interface CustomToken {
  token: string;
}

export default function ConnectMM2() {
  const { setIdToken, authUser, signOut, web3 } = useAppContext();
  const [balance, setBalance] = useState("n/a");
  const [isLoading, setIsLoading] = useState(false);

  //   const functions = getFunctions();
  // const viewBattles = httpsCallable(functions, "viewBattles");

  //connect to firebase with metamask
  const connect = async () => {
    if (window?.ethereum) {
      setIsLoading(true);
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0 && !authUser) {
          //metamask already logged in, but not logged in app
          await authenticate(addressArray[0]);
        } else if (addressArray.length === 0) {
          //metamask not logged in
          console.log("there is metamask installed, but no account");
          //call the sign in function
          const address = await connectWallet();
          await authenticate(address);

          logEvent(getAnalytics(), 'connectedWallet', { address: address });
          setUserId(getAnalytics(), address);
        } else {
          console.log("something is wrong");
        }
      } catch (err) {
        console.log(err);
        console.log("there is metamask installed, but there is an error");
      }
      setIsLoading(false);
    } else {
      alert(
        "you need to install metamask wallet to interact with ethereum blockchain"
      );
      console.log("you must install metamask");
      return "";
    }
  };

  //this connects to an account
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        //this is what activates metamask
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        return addressArray[0];
      } catch (err) {
        console.log("there is metamask, but there is also an error");
        return "";
      }
    } else {
      console.log("there is no metamask");
      return "";
    }
  };

  const handleSignOut = () => {
    signOut();
  };

  useEffect(() => {
    if (window.ethereum) {
      const change = (accounts: string[] | any[]) => {
        //walletListener(accounts[0]);
        console.log("account changed", accounts[0]);
        handleSignOut();
      };

      window.ethereum.on("accountsChanged", change);

      return () => {
        window.ethereum.removeListener("accountsChanged", change);
      };
    }
  }, []);

  useEffect(() => {
    //the following code is for displaying real-time token balance
    const db = getFirestore();
    if (authUser) {
      console.log("authUser is now", authUser.uid);

      const unsub = onSnapshot(doc(db, "users", authUser.uid), (doc) => {
        const data = doc.data();
        const balance = data === undefined ? "n/a" : data.tokenBalance;
        setBalance(balance);
        console.log("Current balance: ", balance);
      });
      return () => unsub();
    }
  }, [authUser?.uid]);

  const getNonce = async (_account: string) => {
    const res = await fetch(
      //why is process.env undefined?
      //`${process.env.REACT_APP_BACKEND_URL}/getNonceToSign`,
      "https://us-central1-w3nftbattle.cloudfunctions.net/getNonceToSign",
      // "http://localhost:5001/w3nftbattle/us-central1/getNonceToSign",
      {
        method: "POST",
        body: JSON.stringify({ publicAddress: _account.toLowerCase() }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      }
    );
    const json: NonceResponse = await res.json();
    console.log(json.nonce);
    return json.nonce;
  };

  //this has to be exactly the same as the one on the frontend!
  const fullMessage = (nonce: string, wallet: string) => {
    return `Welcome to NFT Battle!\n\nBy connecting you agree and accept NFT Battles Terms of Service.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${wallet}\n\nNonce:\n${nonce}`;
  };
  const signMessage = async (publicAddress: string, nonce: string) => {
    if (web3) {
      console.log(web3);

      web3.eth.getBlockNumber().then(console.log);
      const signature = await web3.eth.personal.sign(
        fullMessage(nonce, publicAddress),
        publicAddress,
        "dummy_password"
      );

      console.log("signing okay");
      return signature;
    } else {
      console.log("no web3");
      return "";
    }
  };
  const getCustomToken = async (publicAddress: string, signature: string) => {
    //again, ENV is undefined
    //fetch(`${process.env.REACT_APP_BACKEND_URL}/verifySignedMessage`, {
    //http://localhost:5001/w3nftbattle/us-central1/verifySignedMessage
    const customID = await fetch(
      // `http://localhost:5001/w3nftbattle/us-central1/verifySignedMessage/
      //   `,
      "https://us-central1-w3nftbattle.cloudfunctions.net/verifySignedMessage",
      {
        method: "POST",
        body: JSON.stringify({
          publicAddress: publicAddress.toLowerCase(),
          signature: signature.toLowerCase(),
        }),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      }
    );
    console.log(customID);
    return customID.json();
  };
  const logInFirebase = async (_customID: CustomToken) => {
    //sign in with token
    const auth = getAuth();
    const credential = await signInWithCustomToken(auth, _customID.token);

    const idToken = await credential.user.getIdToken();
    setIdToken(idToken);
  };

  const authenticate = async (_account: string) => {
    if (!_account || _account === "") {
      return;
    }

    try {
      const _nonce = await getNonce(_account);
      console.log("got nonce");
      const _signature = await signMessage(_account, _nonce);
      console.log("got sig", _signature);
      const _customToken = await getCustomToken(_account, _signature);
      console.log("got token", _customToken);
      await logInFirebase(_customToken);
      console.log("logged in");
    } catch {
      throw Error("signature is undefined");
    }
  };

  if (isMobile && !window.ethereum) {
    return (
      <div className="d-inline-flex flex-column mt-4">
        <a href="https://metamask.app.link/dapp/nftduels.games">
          <Button>Connect</Button>
        </a>
        <div
          className="d-flex"
          style={{
            fontWeight: "600",
            fontSize: "20",
          }}
        >
          <p style={{ color: "#707A83" }}>Balance: n/a</p>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-0">
      {authUser === null ? (
        <div className="d-inline-flex flex-column mt-4">
          <Button disabled={isLoading} onClick={connect}>
            {isLoading && (
              <span className="spinner-grow spinner-grow-sm me-2"></span>
            )}
            Connect
          </Button>
          <div
            className="d-flex"
            style={{
              fontWeight: "600",
              fontSize: "20",
            }}
          >
            <p style={{ color: "#707A83" }}>Balance: n/a</p>
          </div>
        </div>
      ) : (
        <div className="d-inline-flex flex-column mt-4">
          <DropdownButton
            // onClick={handleSignOut}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontWeight: "bold", fontSize: "40" }}
            title={
              authUser.uid.slice(0, 6) +
              "..." +
              authUser.uid.slice(authUser.uid.length - 4, authUser.uid.length)
            }
          >
            <Dropdown.Item>
              <Nav.Link to="/myvotes" as={NavLink}>
                My Votes
              </Nav.Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Nav.Link to="/mybattles" as={NavLink}>
                My NFTs
              </Nav.Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Nav.Link to="/buytokens" as={NavLink}>
                Tokens
              </Nav.Link>
            </Dropdown.Item>

            <Dropdown.Item onClick={handleSignOut}>Log Out</Dropdown.Item>
          </DropdownButton>

          <div
            className="d-flex"
            style={{
              fontWeight: "600",
              fontSize: "20",
            }}
          >
            <p style={{ color: "#707A83" }}>Balance: </p>
            <p style={{ color: "#353840" }}>
              {" "}
              {(Math.round(Number(balance) * 100) / 100).toFixed(2)} NDT
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
