import { useState } from "react";
import { useAppContext } from "../context/AppContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";

function SubmitNFT() {
  const [nftAddress, setNftAddress] = useState("");
  const [nftName, setNftName] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [nftLink, setNftLink] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [tokenId2, setTokenId2] = useState("");

  const [battleNumber, setBattleNumber] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [nftConfirmed, setNFTConfirmed] = useState(false);
  const [chainType, setChainType] = useState("");
  // const [imageUrl, setImageUrl] = useState("/imgs/Cool_Cat_numbet6933.png");
  const [imageUri, setImageUri] = useState("/imgs/default.jpeg");

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const functions = getFunctions();
  const submitNFT3 = httpsCallable(functions, "submitNFT3");

  async function handleClick() {
    if (
      nftAddress === "" ||
      tokenId === "" ||
      battleNumber === "" ||
      chainType === "" ||
      nftName === "" ||
      collectionName === "" ||
      imageUri === ""
    ) {
      alert("input missing!");
      return;
    }
    try {
      console.log(
        nftLink,
        nftName,
        collectionName,
        imageUri,
        nftAddress,
        tokenId,
        chainType
      );
      await submitNFT3({
        link: nftLink,
        fullName: nftName,
        collectionName: collectionName,
        imageUri: imageUri,
        nftAddress: nftAddress,
        nftTokenId: tokenId,
        chainType: chainType,

        battleNumber: battleNumber,
      });

      alert(
        "Your NFT is successfully submitted!\nGo to 'Results' -> 'My NFTs' to see your duels"
      );
    } catch (err) {
      let msg = "This NFT cannot be submitted. Try another one.";
      if (err instanceof Error) {
        msg = err.message;
      } else {
        msg = String(err);
      }

      alert(msg);
    } finally {
      setNftAddress("");
      setTokenId("");
      setBattleNumber("");
      setNFTConfirmed(false);
      setNftName("");
      setTokenId2("");
      setImageUri("imgs/default.jpeg");
    }
  }

  const extractNFTInfo = async (originalLink: string) => {
    //for ethereum, whether it's ERC721 or ERC1155, we need to get (1) address, (2) tokenID
    //for solana, we need to get the mint address

    //we will use openSea api for now for etheruem, and MagicEden api for Solana

    let fullName: string = "";
    let collectionName: string = "";
    let imageUri: string = ""; //this is the image uri from marketplace
    let chainType: string = "";
    let nftAddress: string = "";
    let nftTokenId: string = ""; //if solana, this is not used in API call

    const link = originalLink; //it's case sensitive //.toLowerCase();
    let linkArray;
    if (link.includes("opensea.io")) {
      linkArray = link.split("/assets/")[1].split("/");
      if (linkArray[0] === "ethereum" && linkArray.length === 3) {
        chainType = "ethereum";
        nftAddress = linkArray[1];
        nftTokenId = linkArray[2];
      } else if (linkArray[0] === "solana" && linkArray.length === 2) {
        chainType = "solana";
        nftAddress = linkArray[1];
        nftTokenId = "0x"; //this is a dummy number, indicating solana
      } else if (linkArray[0] === "matic" && linkArray.length === 3) {
        //e.g., https://opensea.io/assets/matic/0xdb46d1dc155634fbc732f92e853b10b288ad5a1d/34030
        chainType = "polygon";
        nftAddress = linkArray[1];
        nftTokenId = linkArray[2];
      }
    } else if (link.includes("magiceden.io")) {
      linkArray = link.split("/item-details/")[1].split("/");

      if (linkArray[0] === "eth" && linkArray.length === 3) {
        //e.g., https://magiceden.io/item-details/eth/0xba30e5f9bb24caa003e9f2f0497ad287fdf95623/3706
        chainType = "ethereum";
        nftAddress = linkArray[1];
        nftTokenId = linkArray[2];
      } else if (linkArray.length === 1) {
        //e.g., https://magiceden.io/item-details/521BXx7R3cV72eVcCvoQArVvKb3DxLQX2VnjKoi53xi7?name=DeGod-%23247
        chainType = "solana";
        nftAddress = linkArray[0].split("?")[0];
        nftTokenId = "0x"; //this is a dummy number, indicating solana
      } else if (linkArray[0] === "polygon" && linkArray.length === 3) {
        //e.g., https://polygon.magiceden.io/item-details/polygon/0xa2a13ce1824f3916fc84c65e559391fc6674e6e8/1
        chainType = "polygon";
        nftAddress = linkArray[1];
        nftTokenId = linkArray[2];
      }
    } else if (link.includes("looksrare.org")) {
      linkArray = link.split("/collections/")[1].split("/");
      if (linkArray.length === 2) {
        chainType = "ethereum";
        nftAddress = linkArray[0];
        nftTokenId = linkArray[1];
      }
    } else if (link.includes("x2y2.io")) {
      linkArray = link.split("/eth/")[1].split("/");
      if (linkArray.length === 2) {
        chainType = "ethereum";
        nftAddress = linkArray[0];
        nftTokenId = linkArray[1];
      }
    }

    //using api to extract NFT info (for ethereum and solana)
    if (chainType === "ethereum" && nftAddress !== "" && nftTokenId !== "") {
      //use openSea API for everything
      try {
        const options = { method: "GET" };
        const res = await fetch(
          `https://api.opensea.io/api/v1/asset/${nftAddress}/${nftTokenId}/?include_orders=false`,
          options
        );
        const res2 = await res.json();
        console.log(res2);
        //for some reasons, the .name filed is null for some nft like BYAC

        fullName = res2.name
          ? res2.name
          : `${res2.asset_contract.name} #${nftTokenId}`; //this name already include tokenID
        collectionName = res2.asset_contract.name;
        imageUri = res2.image_url;
      } catch (err) {
        console.log(err);
      }
    } else if (
      chainType === "polygon" &&
      nftAddress !== "" &&
      nftTokenId !== ""
    ) {
      // get polygon NFT with Opensea V2 API:
      try {
        const options = { method: "GET" };
        const res = await fetch(
          `https://api.opensea.io/api/v2/metadata/matic/${nftAddress}/${nftTokenId}`,
          options
        );
        const res2 = await res.json();
        console.log(res2);

        fullName = res2.name ? res2.name : "unknown name";
        collectionName = res2.name
          ? res2.name.split(" #")[0] //hacky
          : "unknown collection";
        imageUri = res2.image;
      } catch (err) {
        console.log(err);
      }
    } else if (
      chainType === "solana" &&
      nftAddress !== "" &&
      nftTokenId !== ""
    ) {
      console.log("solana");
      try {
        console.log(nftAddress);
        const requestOptions = {
          method: "GET",
          //   redirect: "follow",
        };

        const res = await fetch(
          `https://api-mainnet.magiceden.dev/v2/tokens/${nftAddress}`,
          requestOptions
        );
        console.log(res);
        const res2 = await res.json();
        console.log(res2);
        fullName = res2.name;
        collectionName = res2.collection;
        imageUri = res2.image;
      } catch (err) {
        console.log(err);
      }
    }
    return {
      fullName,
      collectionName,
      imageUri, //this is the image uri from marketplace
      chainType,
      nftAddress, // corresponds to 'address' in NFT model, required
      nftTokenId,
    };
  };

  const generatePreview2 = async () => {
    setIsConfirming(true);

    try {
      const nftResult = await extractNFTInfo(nftLink);

      setNftAddress(nftResult.nftAddress);
      setTokenId(nftResult.nftTokenId);
      setTokenId2(nftResult.nftTokenId);

      setNftName(nftResult.fullName);
      setCollectionName(nftResult.collectionName);
      setImageUri(nftResult.imageUri);
      setChainType(nftResult.chainType);

      setNFTConfirmed(true);
      console.log(nftResult.imageUri);
    } catch (err) {
      console.log(err);

      let msg = "This NFT cannot be found. Try another one :(";
      if (err instanceof Error) msg = err.message;

      alert(msg);

      setNFTConfirmed(false);
      setImageUri("imgs/default.jpeg");
    }
    setIsConfirming(false);
  };

  function PreviewButton() {
    if (nftLink !== "" && battleNumber !== "") {
      return (
        <button
          disabled={isConfirming}
          type="button"
          className="btn btn-primary btn-block btn-lg"
          onClick={generatePreview2}
        >
          {isConfirming && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          Preview NFT
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-primary btn-block btn-lg"
        >
          {isConfirming && (
            <span className="spinner-grow spinner-grow-sm"></span>
          )}
          Preview NFT
        </button>
      );
    }
  }

  function SubmitButton() {
    if (
      nftLink !== "" &&
      battleNumber !== "" &&
      nftAddress &&
      nftName &&
      imageUri
    ) {
      return (
        <button
          disabled={isSubmitting}
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          {isSubmitting && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          Submit NFT
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          {isSubmitting && (
            <span className="spinner-grow spinner-grow-sm"></span>
          )}
          Submit NFT
        </button>
      );
    }
  }

  return (
    <div className="container" style={{ minHeight: "80vh" }}>
      {isMobile && <hr />}
      <h1
        className="text-center"
        style={{ fontWeight: "700", color: "#353840" }}
      >
        Submit Your NFT to Duel
      </h1>
      <div className="container">
        <br></br>
        <div className="row">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-4 col-md-5">
            <form onSubmit={handleSubmit(handleClick)}>
              <div>
                <label className="float-start">
                  <b> NFT Link</b>
                </label>
              </div>
              <div className="input-group mb-4">
                <input
                  type="text"
                  value={nftLink}
                  onChange={(e) => {
                    setNftLink(e.target.value);
                    setNFTConfirmed(false);
                    setNftName("");
                    setImageUri("imgs/default.jpeg");
                  }}
                  className="form-control form-control-lg"
                  placeholder=""
                  style={{ borderRadius: 5 }}
                  required
                ></input>
                <div className="form-text">
                  Paste link to your NFT item. Currently supporting: Opensea,
                  Magic Eden, Looksrare, X2Y2.
                </div>
              </div>

              <br />

              <div>
                <label className="float-start">
                  <b>How Many Duels?</b>
                </label>
              </div>

              <div className="input-group mb-4">
                <input
                  type="text"
                  value={battleNumber}
                  onChange={(e) => {
                    setBattleNumber(e.target.value);
                    //setNFTConfirmed(false);
                    //setNftName("");
                    //setImageUrl("");
                  }}
                  style={{ borderRadius: 5 }}
                  className="form-control form-control-lg"
                  placeholder=""
                  required
                ></input>
                <div className="form-text">
                  Each duel costs 10 NDTs. Your NFT will be matched with a
                  randomly selected NFT for duel!
                </div>
                <br />
              </div>

              <div className="d-flex justify-content-center">
                {nftConfirmed && <SubmitButton />}
                {!nftConfirmed && <PreviewButton />}
              </div>
              <br></br>
              <br></br>
            </form>
          </div>
          <div className="col-lg-4 col-md-5">
            {isConfirming ? (
              <div className="d-flex flex-column align-items-center">
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <br></br>
                <h3 className="mx-auto">&nbsp;Loading NFT Image...</h3>
              </div>
            ) : imageUri ? (
              <div className="card h-100 w-100 border-0">
                <img
                  className="card-img-top"
                  src={imageUri} //{{ uri: imageUrl }} //"/imgs/Cool_Cat_numbet6933.png"
                  alt="img"
                  height="380px"
                  // width="300px"
                  style={{
                    objectFit: "cover",
                    borderRadius: "20px",
                  }}
                ></img>
                <div className="card-body">
                  <h5 className="card-title text-center">
                    {nftName}
                    {/* {nftName ? `${nftName} #${tokenId2}` : ""} */}
                  </h5>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="col-lg-2 col-md-1"></div>
        </div>
        <br></br>
      </div>
    </div>
  );
}

export default SubmitNFT;
