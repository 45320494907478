export const convertTime = (epochTime: number) => {
  // const nowE = Number(Date.now());
  // const now = new Date(nowE).toString().split(" ");
  // const result = new Date(epochTime).toString().split(" ");
  // //example format: Thu Mar 06 2014 06:17:34 GMT-0500 (EST)
  // //return JSON.stringify(result);

  // //implement in future
  // //it's not easy to determine if a date is within the some period
  // if (result[1] === now[1] && result[2] === now[2] && result[3] === now[3]) {
  //   return "Today " + result[4].slice(0, 5);
  // } else {
  //   return result[1] + "-" + result[2] + "-" + result[3];
  // }

  const nowNumber = Date.now();
  const now = new Date(nowNumber);
  const nowDate = now.getDate();
  const nowMonth = now.getMonth(); //getMonth is zero based value, 0 is Jan, 1 is Feb, etc.
  const nowYear = now.getFullYear();

  const battleTime = new Date(epochTime);

  const today = new Date(nowYear, nowMonth, nowDate).getTime();
  const yesterday = new Date(nowYear, nowMonth, nowDate - 1).getTime();
  const tomorrow = new Date(nowYear, nowMonth, nowDate + 1).getTime();

  if (epochTime < tomorrow && epochTime >= today) {
    return "Today " + battleTime.toTimeString().split(" ")[0].slice(0, 5);
  } else if (epochTime >= yesterday && epochTime < today) {
    return "Yesterday " + battleTime.toTimeString().split(" ")[0].slice(0, 5);
  } else if (epochTime > tomorrow) {
    //since each battle is 24 hours, you will not see somehting more than 24 hours away
    return "Tomorrow " + battleTime.toTimeString().split(" ")[0].slice(0, 5);
  } else {
    // times older than yesterday
    return battleTime.toDateString().slice(4, 15); // expected output: Wed Jul 28 1993
  }
};
