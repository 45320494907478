import Accordion from "react-bootstrap/Accordion";

export default function Faq() {
  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <div className="row">
        <div className="col-lg-3"></div>

        <div className="col-lg-6">
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is NFT Duel?</Accordion.Header>
              <Accordion.Body>
                NFT Duel is an NFT-based game, where users get to vote on the
                their preferred NFT in a duel. It is inspired by the "Hot or
                Not" style game with a web3 angle.
                <br />
                <br />
                Each duel lasts for 24 hours, and the results will be published
                when the duel finishes. Each vote costs 1 platform native token,
                NFT Duels Token (NDT).
                <br />
                <br />
                Those who correctly predicted the result (i.e., voted on the NFT
                that has more votes), will be rewarded with more NDTs.
                <br />
                <br />
                Anyone can also create new Duels by submitting an NFT, which
                will be randomly paired with another NFT. Submitting an NFT for
                Duel cost 10 NDTs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>Why do you create NFT Duel?</Accordion.Header>
              <Accordion.Body>
                We create NFT duels for fun, and for a better way to rank NFTs.{" "}
                <br />
                <br />
                Almost all NFT markets now rank NFTs based on price and trading
                volume. This makes it difficult for new projects to get any
                discoverability. <br />
                <br />
                We believe the community perception is better than price and
                trading info when evaluating a piece of art work. So when you
                are playing this game, you are also helping the NFT community to
                filter out the best NFTs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Do I need to log in with Metamask to play?
              </Accordion.Header>
              <Accordion.Body>
                Yes, to play NFT Duels, you will need to sign in with your
                metamask wallet.
                <br />
                <br />
                We airdrop NDTs to your account when you first sign in for you
                to start playing NFT Duels. This is similar to signing into
                Opensea or web3 applications, and we do not store any of your
                sensitive information.
                <br />
                <br />
                We will implement other wallets in the furture, but for now, we
                only support metamask.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Do you keep my wallet information?
              </Accordion.Header>
              <Accordion.Body>
                We only keep your public address, and you do not expose any
                sensitive information such as private key to us.{" "}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>How to play NFT Duel?</Accordion.Header>
              <Accordion.Body>
                You can go to the Battle page, and will see pairs of NFTs in
                duels. Simply click on the one you think will be more popular,
                and hit "submit" button. Each vote costs 1 NDT, and you can see
                your current NDT balance on the top right corner.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>How long is each duel?</Accordion.Header>
              <Accordion.Body>Each duel lasts 24 hours. </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>How to I create new Duels?</Accordion.Header>
              <Accordion.Body>
                Click "Create Duels" in menu bar, and enter (1) the NFT address,
                (2) the token ID, and (3) the number of duels you would like to
                create.
                <br />
                <br />
                Please note you can only submit ERC721-type NFT for now.
                <br />
                <br />
                Each duel will cost 10 NDTs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                What is NFT Duel Tokens (NDT)?
              </Accordion.Header>
              <Accordion.Body>
                NFT Duel tokens (NDT) is the native to the NFT Duel app.
                <br />
                <br />
                Each vote and each creation of NFT duel costs certain NDTs. Each
                winning vote in duels will reward the user with additioanl NDTs.
                <br />
                <br />
                It's ERC20 and will be released on the Ethereum platform when
                the beta test finishes.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How do I get Duel Tokens?</Accordion.Header>
              <Accordion.Body>
                Each new wallet logging in into NFT Duel will be rewarded with
                100 NDTs.
                <br />
                <br />
                In our public beta we do not allow additional buying or selling
                of NDTs. Our goal is to build the community for now, and will
                allow the trading of NDTs in the future.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                How do I withdraw my Duel Tokens?
              </Accordion.Header>
              <Accordion.Body>
                Right now we are doing a beta test of the NFT duel app. You
                won't be able to buy, sell, withdraw, or deposit your NDTs. You
                will also be rewarded 100 NDTs when you first log in.
                <br />
                <br />
                We plan to conclude the public beta early in 2023, by then you
                will be able to withdraw your NDTs to your web3 wallet, or
                purchase additional NDTs.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How many NDTs can I win from a duel?
              </Accordion.Header>
              <Accordion.Body>
                The number of NDTs you can win depends on the number of
                participants as well as the initial NDTs cost to create the
                duel.{" "}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Who can create new NFT duels?</Accordion.Header>
              <Accordion.Body>
                Anyone who logged in the app! Each new duel will cost 10 NDTs,
                so each user can technically create 10 duels (each new user is
                airdropped 100 NDTs). <br></br>
                <br></br>
                In the public beta, you do not need to own the actual NFT to
                create a duel for it.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="col-lg-3"></div>
      </div>
    </div>
  );
}
