import Countdown from "react-countdown";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import { isMobile } from "react-device-detect";

export default function DummySlides() {
  const slide = (nft1: string, nft2: string) => {
    return (
      <>
        <SwiperSlide key={nft1 + nft2}>
          <div>
            <div className="d-flex flex-column">
              <div
                className="d-flex justify-content-center"
                style={{ color: "#707A83" }}
              >
                Reward Pool:&nbsp;
                <div style={{ fontWeight: "600", color: "#2081E2" }}>
                  {Math.floor(Math.random() * 10000) + 200} NDT
                </div>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ color: "#707A83" }}
              >
                Ending In:&nbsp;
                <div style={{ fontWeight: "600", color: "#2081E2" }}>
                  <Countdown
                    date={
                      Date.now() + Math.floor(Math.random() * 1000000) + 3600000
                    }
                    daysInHours={true}
                  ></Countdown>
                </div>
              </div>
            </div>
            <br />

            {!isMobile && (
              <div className="d-flex">
                <div className="col-1"></div>
                <div className="col-4">
                  <div className="card h-100 border-0">
                    <img
                      src={"imgs/" + nft1}
                      className="card-img-top"
                      alt="first nft"
                      height="200px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                    />

                    <div className="card-body">
                      <h5 className="card-title" style={{ color: "#353840" }}>
                        {nft1
                          .split(".")[0]
                          .replace("numbet", "#")
                          .replaceAll("_", " ")}
                        {/* Cool Cat #6933 */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className=" col-2 d-flex justify-content-center align-items-center">
                  <h1
                    style={{
                      fontWeight: "700",
                      fontSize: "48px",
                      color: "#353840",
                    }}
                  >
                    VS
                  </h1>
                </div>

                <div className="col-4">
                  <div className="card h-100 border-0">
                    <img
                      className="card-img-top"
                      src={"/imgs/" + nft2}
                      alt="2nd NFT"
                      height="200px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                    />

                    <div className="card-body">
                      <h5 className="card-title">
                        {nft2
                          .split(".")[0]
                          .replace("numbet", "#")
                          .replaceAll("_", " ")}
                        {/* DigiDaigaku #1401 */}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            )}

            {isMobile && (
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <div className="d-flex flex-column mb-3">
                    <div>
                      <div>
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ color: "#353840" }}
                          >
                            {nft1
                              .split(".")[0]
                              .replace("numbet", "#")
                              .replaceAll("_", " ")}
                            <br />
                            <br />
                          </h5>
                        </div>
                        <div className="card h-100 border-0">
                          <img
                            src={"imgs/" + nft1}
                            className="card-img-top"
                            alt="first nft"
                            height="200px"
                            style={{
                              objectFit: "cover",
                              borderRadius: "20px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="justify-content-center align-items-center">
                        <h1
                          style={{
                            fontWeight: "700",
                            fontSize: "48px",
                            color: "#353840",
                          }}
                        >
                          VS
                        </h1>
                      </div>

                      <div>
                        <div className="card h-100 border-0">
                          <img
                            className="card-img-top"
                            src={"/imgs/" + nft2}
                            alt="2nd NFT"
                            height="200px"
                            style={{
                              objectFit: "cover",
                              borderRadius: "20px",
                            }}
                          />

                          <div className="card-body mt-0">
                            <h5 className="card-title">
                              {nft2
                                .split(".")[0]
                                .replace("numbet", "#")
                                .replaceAll("_", " ")}

                              {/* {choice2Info[0] + " #" + choice2Info[1]} */}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            )}
          </div>
        </SwiperSlide>
      </>
    );
  };

  const promptLogInView = (
    <div className="container">
      <br></br>
      <br></br>
      <div>
        {!isMobile ? (
          <h1
            className="text-center"
            style={{ fontWeight: "700", fontSize: "64px", color: "#353840" }}
          >
            Connet wallet to rate NFT
          </h1>
        ) : (
          <h1
            className="text-center"
            style={{ fontWeight: "700", fontSize: "32px", color: "#353840" }}
          >
            Connet wallet to rate NFT
          </h1>
        )}
        {/* {!isMobile ? (
          <h4 style={{ color: "#353840" }} className="text-center">
            Win token rewards
          </h4>
        ) : (
          <h4 style={{ color: "#353840" }} className="text-center">
            Win token rewards
          </h4>
        )} */}
      </div>
      {isMobile && <hr></hr>}
      <Swiper
        navigation={true}
        allowTouchMove={false}
        modules={[Pagination, Navigation]}
      >
        {slide("Cool_Cat_numbet6933.png", "Digidaigaku_numbet1401.png")}
        {slide("Lets_Walk_numbet067.webp", "Doge_Club_numbet8725.png")}
        {slide("Doodle_numbet1767.png", "Goblintown_numbet4559.png")}
        {slide("Kitaro_numbet6079.png", "Nyoling_numbet2738.png")}
      </Swiper>
    </div>
  );

  return promptLogInView;
}
