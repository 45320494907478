// import required modules
import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { QuestionType } from "../interface/QuestionType";
import "./BattleSlide.css";
import Countdown from "react-countdown";
import { getImageUris, getNFTLinks, getNFTNames } from "./GetNFTMetadata";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent } from "firebase/analytics";

type Props = {
  allQuestions: QuestionType[];
  question: QuestionType;
  setQuestionsToView: (val: Array<QuestionType>) => void;
  setUpdateAt: (val: number) => void;
  nextUpdateAt: number;
};

//change this to a dedicated one in the future
const GATEWAY = "https://gateway.pinata.cloud/ipfs/"; //"https://tachyon.mypinata.cloud/ipfs/"; //

const COST_PER_BATTLE = 10;

const BattleSlide: React.FC<Props> = (props) => {
  const [firstNFT, setFirstNFT] = useState(false);
  const [secondNFT, setSecondNFT] = useState(false);
  const [firstNftImage, setFirstNftImage] = useState<string>("");
  const [secondNftImage, setSecondNftImage] = useState<string>("");
  const [firstNftName, setFirstNftname] = useState<string>();
  const [secondNftName, setSecondNftname] = useState<string>();
  const [firstNftLink, setFirstNftLink] = useState<string>();
  const [secondNftLink, setSecondNftLink] = useState<string>();

  const [finishedAt, setFinishedAt] = useState<number>();
  const [totalParticipantCount, setTotalParticipantCount] = useState<number>();

  const [choice1Info, setChoice1Info] = useState<string[]>([
    "Uknown",
    "Uknown",
  ]);
  const [choice2Info, setChoice2Info] = useState<string[]>([
    "Uknown",
    "Uknown",
  ]);

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // const styleOption = {
  //   borderColor: "#0275d8",
  //   borderWidth: 0,
  // };

  const functions = getFunctions();
  const answerQuestion = httpsCallable(functions, "answerQuestion");

  const allQuestions = props.allQuestions;
  const question = props.question;
  const setQuestionsToView = props.setQuestionsToView;
  const setUpdateAt = props.setUpdateAt;

  //set the images for both nfts
  useEffect(() => {
    const init = async () => {
      try {
        const imageUris = await getImageUris(question);
        setFirstNftImage(imageUris[0]);
        setSecondNftImage(imageUris[1]);
        const nftNames = await getNFTNames(question);
        setFirstNftname(nftNames[0]);
        setSecondNftname(nftNames[1]);
        const nftLinks = await getNFTLinks(question);
        setFirstNftLink(nftLinks[0]);
        setSecondNftLink(nftLinks[1]);

        setFinishedAt(question.finishedAt);

        setTotalParticipantCount(question.totalParticipantCount);

        setChoice1Info([question.choice1Name, question.choice1TokenId]);
        setChoice2Info([question.choice2Name, question.choice2TokenId]);
      } catch (err) {
        console.log(err);
      }
    };
    init();
  }, []);

  const confirmSubmit = async () => {
    let _answerChoice;
    if (firstNFT) {
      _answerChoice = "1";
    } else if (secondNFT) {
      _answerChoice = "2";
    } else if ((firstNFT && secondNFT) || (!firstNFT && !secondNFT)) {
      return;
    }

    try {
      await answerQuestion({
        questionUid: question.uid,
        answerChoice: _answerChoice,
      });
      logEvent(getAnalytics(), 'answerQuestion', { picked: _answerChoice });
      const filteredQuestions = allQuestions.filter(
        (q) => q.uid !== question.uid
      );
      if (filteredQuestions.length !== 0) {
        setQuestionsToView(filteredQuestions);
        setFirstNFT(false);
        setSecondNFT(false);
      } else {
        setUpdateAt(props.nextUpdateAt);
      }
    } catch (error: any) {
      console.log(error.message);

      if (error.message === "you do not have enough balance.") {
        alert("you do not have enough balance");
      }
    }
  };

  const handleClick1stNFT = async (e: React.MouseEvent<HTMLImageElement>) => {
    if (!firstNFT) {
      setFirstNFT(true);
      setSecondNFT(false);
    } else {
      setFirstNFT(false);
    }
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
  };

  const handleClick2ndNFT = async (e: React.MouseEvent<HTMLImageElement>) => {
    if (!secondNFT) {
      setSecondNFT(true);
      setFirstNFT(false);
    } else {
      setSecondNFT(false);
    }
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 1000);
  };

  return finishedAt === undefined || totalParticipantCount === undefined ? (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : (
    <div>
      <div className="d-flex flex-column">
        <div
          className="d-flex justify-content-center"
          style={{ color: "#707A83" }}
        >
          Price Pool:&nbsp;
          <div style={{ fontWeight: "600", color: "#2081E2" }}>
            {totalParticipantCount + COST_PER_BATTLE}
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ color: "#707A83" }}
        >
          Ending In:&nbsp;
          <div style={{ fontWeight: "600", color: "#2081E2" }}>
            <Countdown date={finishedAt} daysInHours={true}></Countdown>
          </div>
        </div>
      </div>
      <br />

      {!isMobile && (
        <div className="d-flex">
          <div className="col-1"></div>
          <div className="col-4">
            <div className="card h-100 border-0">
              <img
                src={firstNftImage}
                className={`card-img-top ${firstNFT ? "selected" : ""}`}
                alt="first nft"
                height="200px"
                width="200px"
                style={{
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                onClick={(e) => {
                  handleClick1stNFT(e);
                }}
              />

              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ color: "#353840" }}
                  // style={{ fontWeight: "400", fontSize: "32px" }}
                >
                  <a
                    href={firstNftLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {firstNftName}
                  </a>
                  {/* {choice1Info[0] + " #" + choice1Info[1]} */}
                </h5>
              </div>
            </div>
          </div>
          <div className=" col-2 d-flex justify-content-center align-items-center">
            <h1
              style={{ fontWeight: "700", fontSize: "48px", color: "#353840" }}
            >
              VS
            </h1>
          </div>

          <div className="col-4">
            <div className="card h-100 border-0">
              <img
                className={`card-img-top ${secondNFT ? "selected" : ""}`}
                src={secondNftImage}
                alt="2nd NFT"
                height="200px"
                width="200px"
                style={{
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                onClick={(e) => {
                  handleClick2ndNFT(e);
                  //mySwiper.slideNext();
                }}
              />

              <div className="card-body">
                <h5 className="card-title">
                  <a
                    href={secondNftLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {secondNftName}
                  </a>
                  {/* {choice2Info[0] + " #" + choice2Info[1]} */}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      )}

      {/* for mobile version */}
      {isMobile && (
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8">
            <div className="d-flex flex-column mb-3">
              <div>
                <div>
                  <div className="card-body">
                    <h5 className="card-title" style={{ color: "#353840" }}>
                      <a
                        href={firstNftLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {firstNftName}
                      </a>
                    </h5>
                  </div>
                  <div className="card h-100 border-0">
                    <img
                      src={firstNftImage}
                      className={`card-img-top ${firstNFT ? "selected" : ""}`}
                      alt="first nft"
                      height="200px"
                      width="200px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      onClick={(e) => {
                        handleClick1stNFT(e);
                      }}
                    />
                  </div>
                </div>
                <div className="justify-content-center align-items-center">
                  <h1
                    style={{
                      fontWeight: "700",
                      fontSize: "48px",
                      color: "#353840",
                    }}
                  >
                    VS
                  </h1>
                </div>

                <div>
                  <div className="card h-100 border-0">
                    <img
                      className={`card-img-top ${secondNFT ? "selected" : ""}`}
                      src={secondNftImage}
                      alt="2nd NFT"
                      height="200px"
                      width="200px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      onClick={(e) => {
                        handleClick2ndNFT(e);
                        //mySwiper.slideNext();
                      }}
                    />

                    <div className="card-body mt-0">
                      <h5 className="card-title">
                        <a
                          href={secondNftLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {secondNftName}
                        </a>
                        {/* {choice2Info[0] + " #" + choice2Info[1]} */}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      )}

      {firstNFT || secondNFT ? (
        <div>
          <form onSubmit={handleSubmit(confirmSubmit)}>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-lg"
              style={{ fontWeight: "bold", fontSize: "20" }}
              // onClick={confirmSubmit}
            >
              {isSubmitting && (
                <span className="spinner-grow spinner-grow-sm me-2"></span>
              )}
              Submit
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BattleSlide;
