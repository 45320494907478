import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import MyRow from "../utility/MyRow";
import { QuestionType } from "../interface/QuestionType";
import { BsCheckCircleFill } from "react-icons/bs";
import LoadingView from "../utility/LoadingView";

import { isMobile } from "react-device-detect";

//Question: should we use cache so that this page doesn't referesh each time?
function BattleResult() {
  const functions = getFunctions();
  //Question: what's a good way to set its type?
  const getResults = httpsCallable(functions, "getBattleResults");

  //battle data

  const [result, setResult] = useState<QuestionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [bottom, setBottom] = useState(false);

  const [lastTimestamp, setLastTimestamp] = useState<number>();

  useEffect(() => {
    const init = async () => {
      await getData();
    };
    setFirstLoading(true);
    init();
    setFirstLoading(false);
  }, []);

  //calling API endpoint to pull battle results
  const getData = async () => {
    const leng = result.length;
    const timestamp =
      leng === 0 ? Number(Date.now()) : result[leng - 1].finishedAt;

    if (timestamp !== lastTimestamp) {
      setLoading(true);
      const r: any = await getResults({
        number: 10,
        lastTimestamp: timestamp,
      });

      const r2 = r.data;
      let r3 = [];
      for (let i = 0; i < r2.length; i++) {
        r3.push(r2[i].data);
      }

      setResult([...result, ...r3]);
      setLastTimestamp(timestamp);
      setLoading(false);
      if (r3.length === 0) {
        setBottom(true);
      }
    }
  };

  return (
    //    <div style={{ minHeight: "100vh" }}>
    <div>
      {firstLoading ? (
        <LoadingView loadingItem="All Duels" />
      ) : (
        <div
          className={`container d-flex ${
            !isMobile && "justify-content-center"
          }`}
        >
          <div className="col-xs-1 col-sm-2 col-md-3"></div>
          <div className="col-xs-10 col-sm-8 col-md-6 ">
            {isMobile && <hr />}

            <h1
              className="text-center"
              style={{ fontWeight: "700", color: "#353840" }}
            >
              All Finished Duels
            </h1>
            <div className="text-end">
              <BsCheckCircleFill
                className="me-1 ms-4"
                size={20}
                color={"#34C77B"}
              ></BsCheckCircleFill>
              Winner
            </div>
            <br></br>
            <table
              className=" table table-sm table-borderless border-primary align-middle text-center "
              style={{ height: "100vh" }}
            >
              {result.map((r) => {
                return (
                  <tbody key={r.uid}>{<MyRow question={r}></MyRow>}</tbody>
                );
              })}
            </table>
            {loading ? (
              <LoadingView loadingItem="All Duels" />
            ) : bottom ? (
              <>
                <div className="d-flex justify-content-center">
                  <p className="text-muted"> You reached the Bottom </p>
                </div>
                <br></br>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" onClick={getData}>
                    Load More
                  </button>
                </div>
                <br></br>
              </>
            )}
          </div>
          <div className="col-xs-1 col-sm-2 col-md-3"></div>
        </div>
      )}
    </div>
  );
}

export default BattleResult;
