import React, { useEffect, useState } from "react";
import Web3 from "web3";

import { initializeApp, getApp } from "firebase/app";
import {
  getAuth,
  signOut as authSignOut,
  connectAuthEmulator,
  onAuthStateChanged,
  Unsubscribe
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
interface IAuthUser {
  uid: string;
}
interface ContextType {
  web3: Web3;
  account: string;
  chainId: number;
  ready: boolean;
  idToken: string;
  setIdToken: Function;
  authUser: IAuthUser | null;
  isLoading: boolean;
  signOut: Function;
}
export const CustomContext = React.createContext<ContextType>({
  web3: new Web3(),
  account: "undefined",
  chainId: 0,
  ready: false,
  idToken: "",
  setIdToken: () => {},
  authUser: null,
  isLoading: false,
  signOut: () => {},
});

type ProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: ProviderProps) => {
  const [chainId, setChainId] = useState<number>(0);
  const [web3, setWeb3] = useState<Web3>(new Web3());

  const [account, setAccount] = useState<string>("");

  const [ready, setReady] = useState(false);
  const [idToken, setIdToken] = useState("");

  // Authentication related context
  const [authUser, setAuthUser] = useState<IAuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const clear = () => {
    setAccount("");

    setAuthUser(null);
    setIsLoading(false);
  };

  const authStateChanged = async (user: any) => {
    setIsLoading(true);
    if (!user) {
      clear();
      return;
    }
    console.log("setting authuser! ", user.uid);
    setAuthUser({
      uid: user.uid,
    });
    setIsLoading(false);
  };

  const signOut = async () => {
      await authSignOut(getAuth());
      clear()
  }
  ///////
  useEffect(() => {
    const unsubscribes:Array<Unsubscribe> = []
    const init = async () => {
      const _web3 = new Web3(
        Web3.givenProvider ||
          new Web3.providers.HttpProvider(
            "https://mainnet.infura.io/v3/c78e7d19d0724bf5b8b381c80652809e"
          )
      );
      setWeb3(_web3);
      const accounts = await _web3.eth.getAccounts();

      // console.log("current account is", accounts[0]);
      setAccount(accounts[0]);
      const _chainId = await _web3.eth.getChainId();
      setChainId(_chainId);

      //QUESTION: why do we have apiKey on the front end?
      const firebaseConfig = {
        apiKey: "AIzaSyDG4KZjolmfgZCS9Hci3yVocejfXApmyNo",
        authDomain: "w3nftbattle.firebaseapp.com",
        projectId: "w3nftbattle",
        storageBucket: "w3nftbattle.appspot.com",
        messagingSenderId: "492089547435",
        appId: "1:492089547435:web:44b41763f2e795beb335ee",
        measurementId: "G-1DFL8F94BE"
      };
    
      initializeApp(firebaseConfig);

      //for local testing, uncomment block below
      // if (window.location.hostname === "localhost" || "127.0.0.1") {
      //   const func = getFunctions(getApp());
      //   connectFunctionsEmulator(func, "localhost", 5001);

      //   const db = getFirestore();
      //   connectFirestoreEmulator(db, "localhost", 8081);

      //   const auth = getAuth();
      //   connectAuthEmulator(auth, "http://localhost:9099");
      // }

      unsubscribes.push(onAuthStateChanged(getAuth(), authStateChanged))
      setReady(true);
    };
    init();
    return () => unsubscribes.forEach(u => u())
  }, []);

  return (
    <CustomContext.Provider
      value={{
        web3,
        account,
        chainId,
        ready,
        idToken,
        setIdToken,
        authUser,
        isLoading,
        signOut,
      }}
    >
      {children}
    </CustomContext.Provider>
  );
};

//creating a custom hook to consume CostomContext
export const useAppContext = () => React.useContext(CustomContext);
