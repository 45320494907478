import { useEffect, useState } from "react";
import { getNFTInfo } from "./GetNFTMetadata";
import { NftType } from "../interface/NFTType";
import { getNFTLink } from "./Helpers";

type Props = {
  rank: number;
  nft: NftType;
};

const MyNftRow: React.FC<Props> = (props) => {
  const nft = props.nft;
  const rank = props.rank;
  const [img1, setImg1] = useState<string>();
  const [nftName, setNftName] = useState<string>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      const [i1, i2] = await getNFTInfo(nft);
      setImg1(i1);
      setNftName(i2);
      setLoading(true);
    };
    init();
  }, []);

  if (!loading) {
    return <></>;
  }
  return (
    <tr>
      <td
        style={{
          fontSize: "24px",
        }}
      >
        {rank}
      </td>
      <td className="d-flex justify-content-center pt-3 pb-3">
        <a
          href={
            nft.permaLink ? nft.permaLink : getNFTLink(nft.address, nft.tokenId)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              src={img1}
              alt="first nft"
              height="80px"
              width="80px"
              style={{
                display: "block",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
        </a>
      </td>
      <td
        className="text-start"
        style={{
          fontSize: "24px",
        }}
      >
        {nftName}
        {/* {nft.name + " #" + nft.tokenId} */}
      </td>
      <td
        style={{
          fontSize: "24px",
        }}
      >
        {nft.battleCount}
      </td>

      <td
        style={{
          fontSize: "24px",
        }}
      >
        {nft.winCount}
      </td>
      <td
        style={{
          fontSize: "24px",
        }}
      >
        {nft.tieCount}
      </td>
      <td
        style={{
          fontSize: "24px",
        }}
      >
        {nft.lossCount}
      </td>
      <td
        style={{
          fontSize: "24px",
        }}
      >
        {nft.score}
      </td>
    </tr>
  );
};

export default MyNftRow;
