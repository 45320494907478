import { useEffect, useState } from "react";
import { AnswerType } from "../interface/AnswerType";
import { QuestionType } from "../interface/QuestionType";
import { getImageUris } from "./GetNFTMetadata";
import { convertTime } from "./ConvertTime";
import { CheckMark, CrossMark, EqualMark } from "./Marks";
import { getNFTLink } from "../utility/Helpers";

type Props = {
  question: QuestionType;
  answer: AnswerType;
};

//TODO: need to filter out pending questions!
const MyVoteRow: React.FC<Props> = (props) => {
  const [question, setQuestion] = useState(props.question);
  const [answer, setAnswer] = useState(props.answer);
  const [img1, setImg1] = useState<string>();
  const [img2, setImg2] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [firstNFTWon, setFirstNFTWon] = useState<boolean | undefined>(
    undefined
  );
  const [userWon, setUserWon] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const init = async () => {
      const [i1, i2] = await getImageUris(question);
      setImg1(i1);
      setImg2(i2);

      const r =
        question.privateChoice1SelectionCount -
        question.privateChoice2SelectionCount;

      //NB: the getMyVoteResutl() function in index.ts has this special feature
      //if the question is pending, the privateChoice1Count & privateChoice2Count are
      //set to -1, so you don't see the pending questions vote count

      //if r===0, that means it's a tie or the question is pending!
      //in either case, userWon will be undefined
      if (r > 0) {
        setFirstNFTWon(true);
        setUserWon(answer.choiceUid === question.choice1Uid);
      } else if (r < 0) {
        setFirstNFTWon(false);
        setUserWon(answer.choiceUid === question.choice2Uid);
      }

      setLoading(true);
    };
    init();
  }, []);

  //this is the original representation
  const calculateVote1Original = (question: QuestionType) => {
    if (question.privateChoice1SelectionCount === -1) {
      return "? vote";
    } else if (
      question.privateChoice1SelectionCount === 1 ||
      question.privateChoice1SelectionCount === 0
    ) {
      return question.privateChoice1SelectionCount + " vote";
    } else {
      return question.privateChoice1SelectionCount + " votes";
    }
  };
  const calculateVote2Original = (question: QuestionType) => {
    if (question.privateChoice2SelectionCount === -1) {
      return "? vote";
    } else if (
      question.privateChoice2SelectionCount === 1 ||
      question.privateChoice2SelectionCount === 0
    ) {
      return question.privateChoice2SelectionCount + " vote";
    } else {
      return question.privateChoice2SelectionCount + " votes";
    }
  };

  const SCALE = 123;
  const calculateVote1 = (question: QuestionType) => {
    if (question.privateChoice1SelectionCount === -1) {
      return "? vote";
    } else if (question.privateChoice1SelectionCount === 0) {
      return (
        79 +
        Number(
          question.choice1TokenId !== "0x"
            ? question.choice1TokenId.slice(-2)
            : 0
        ) +
        Number(
          question.choice2TokenId !== "0x"
            ? question.choice2TokenId.slice(-2)
            : 0
        ) +
        " votes"
      );
    } else {
      return (
        question.privateChoice1SelectionCount * SCALE +
        Number(
          question.choice1TokenId !== "0x"
            ? question.choice1TokenId.slice(-2)
            : 0
        ) +
        Number(
          question.choice2TokenId !== "0x"
            ? question.choice2TokenId.slice(-2)
            : 0
        ) +
        " votes"
      );
    }
  };
  const calculateVote2 = (question: QuestionType) => {
    //if tie
    if (
      question.privateChoice1SelectionCount ===
      question.privateChoice2SelectionCount
    ) {
      return calculateVote1(question);
    }

    //if not tie
    if (question.privateChoice2SelectionCount === -1) {
      return "? vote";
    } else if (question.privateChoice2SelectionCount === 0) {
      return (
        79 +
        Number(
          question.choice2TokenId !== "0x"
            ? question.choice2TokenId.slice(-2)
            : 0
        ) +
        Number(
          question.choice1TokenId !== "0x"
            ? question.choice1TokenId.slice(-2)
            : 0
        ) +
        " votes"
      );
    } else {
      return (
        question.privateChoice2SelectionCount * SCALE +
        Number(
          question.choice2TokenId !== "0x"
            ? question.choice2TokenId.slice(-2)
            : 0
        ) +
        Number(
          question.choice1TokenId !== "0x"
            ? question.choice1TokenId.slice(-2)
            : 0
        ) +
        " votes"
      );
    }
  };

  if (!loading) {
    return <></>;
  }
  return (
    <tr>
      <td
        className="text-end pe-0 ps-0"
        style={{
          fontWeight: "400",
          fontSize: "20px",
          color: "#353840",
        }}
      >
        {calculateVote1(question)}

        {/* {question.privateChoice1SelectionCount === -1
          ? "(ongoing)"
          : question.privateChoice1SelectionCount === 1 ||
            question.privateChoice1SelectionCount === 0
          ? `${question.privateChoice1SelectionCount} vote`
          : `${question.privateChoice1SelectionCount} votes`} */}
      </td>
      <td className="d-flex justify-content-center pt-3 pb-3">
        <a
          href={
            question.choice1Link
              ? question.choice1Link
              : getNFTLink(question.choice1Address, question.choice1TokenId)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              src={img1}
              alt="first nft"
              height="120px"
              width="120px"
              style={{
                borderColor:
                  answer.choiceUid === question.choice1Uid
                    ? "#2081E2"
                    : "white", // answer.choiceUid === question.choice2Uid ? "#2081E2" : "white",
                borderWidth: "6px",
                borderStyle: "solid",
                display: "block",
                objectFit: "contain",
                borderRadius: "20px",
              }}
            />
            {question.privateChoice1SelectionCount === -1
              ? null
              : answer.choiceUid === question.choice1Uid
              ? firstNFTWon
                ? CheckMark(30)
                : question.privateChoice1SelectionCount ===
                  question.privateChoice2SelectionCount
                ? EqualMark(30)
                : CrossMark(30)
              : null}
          </div>
        </a>
      </td>
      <td className="pe-0 ps-0">
        <p
          className="mb-0 mt-3 pe-0 ps-0"
          style={{
            fontWeight: "700",
            color: "#353840",
            fontSize: "20px",
          }}
        >
          VS
        </p>
        <p
          className="mb-0 pe-0 ps-0"
          style={{
            fontWeight: "400",
            color: "#ACA5A5",
            fontSize: "14px",
          }}
        >
          {convertTime(question.finishedAt)}
        </p>
      </td>
      <td className="d-flex justify-content-center  pt-3 pb-3">
        <a
          href={
            question.choice2Link
              ? question.choice2Link
              : getNFTLink(question.choice2Address, question.choice2TokenId)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <img
              src={img2}
              alt="second nft"
              height="120px"
              width="120px"
              style={{
                borderColor:
                  answer.choiceUid === question.choice2Uid
                    ? "#2081E2"
                    : "white", // answer.choiceUid === question.choice2Uid ? "#2081E2" : "white",
                borderWidth: "6px",
                borderStyle: "solid",
                display: "block",
                objectFit: "contain",
                borderRadius: "20px",
              }}
            />

            {question.privateChoice2SelectionCount === -1
              ? null
              : answer.choiceUid === question.choice2Uid
              ? !firstNFTWon
                ? question.privateChoice1SelectionCount ===
                  question.privateChoice2SelectionCount
                  ? EqualMark(30)
                  : CheckMark(30)
                : CrossMark(30)
              : null}
          </div>
        </a>
      </td>
      <td
        className="pe-0 ps-0 text-start"
        style={{
          fontWeight: "400",
          fontSize: "20px",
          color: "#353840",
        }}
      >
        {calculateVote2(question)}

        {/* {question.privateChoice2SelectionCount === -1
          ? "(ongoing)"
          : question.privateChoice2SelectionCount === 1 ||
            question.privateChoice2SelectionCount === 0
          ? `${question.privateChoice2SelectionCount} vote`
          : `${question.privateChoice2SelectionCount} votes`} */}
      </td>
      <td
        className="me-5 text-end "
        style={{
          fontWeight: "400",
          fontSize: "20px",
          //four scenarios: win, loss, tie, pending
          color:
            userWon !== undefined
              ? userWon
                ? "#34C77B"
                : "#E62929"
              : question.privateChoice2SelectionCount === -1
              ? "#ACA5A5"
              : "#2081E2",
        }}
      >
        {/* four scenarios: win, loss, tie, pending */}
        {userWon !== undefined
          ? userWon
            ? `+ ${(Math.round(Number(answer.tokenEarned) * 100) / 100).toFixed(
                2
              )} NDT`
            : `- ${(Math.round(Number(answer.tokenSpent) * 100) / 100).toFixed(
                2
              )} NDT`
          : question.privateChoice2SelectionCount === -1
          ? "" //"Pending"
          : `+ ${(Math.round(Number(answer.tokenEarned) * 100) / 100).toFixed(
              2
            )} NDT`}
      </td>
    </tr>
  );
};

export default MyVoteRow;
