import React from "react";
import "./footer.css";
import {
  BsDiscord,
  BsTwitter,
  BsMedium,
  BsReddit,
  BsFacebook,
} from "react-icons/bs";

import { isMobile } from "react-device-detect";

const Footer = () => (
  <div
    className="container-fluid"
    // style={{ color: "white", backgroundColor: "#1868B7" }}
  >
    <div className="container">
      <hr />
      <div className="row" style={{ fontSize: "12px" }}>
        <div className="col-6">
          {" "}
          <span className="ms-0">
            {!isMobile
              ? "Copyright 2023 NFT Duels | Discover NFT Value Through Community"
              : "Copyright 2023 NFT Duels"}
          </span>
        </div>
        <div className="col-6 text-end">
          {" "}
          <a
            href="https://discord.gg/JrvsDhyZsU"
            target="_blank"
            rel="noreferrer"
          >
            <BsDiscord size={25} className="me-3"></BsDiscord>
          </a>
          <a
            href="https://twitter.com/Nftduels"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter size={25} className="me-3"></BsTwitter>
          </a>
          <a
            href="https://www.facebook.com/groups/533851782025705"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook size={25} className="me-3"></BsFacebook>
          </a>
          {/* <a
            href="https://twitter.com/Nftduels"
            target="_blank"
            rel="noreferrer"
          >
            <BsReddit size={25} className="me-3"></BsReddit>
          </a> */}
        </div>
      </div>

      <hr />
    </div>
  </div>
);

export default Footer;
