import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { NftType } from "../interface/NFTType";
import MyNftRow from "../utility/MyNFTRow";
import LoadingView from "../utility/LoadingView";
import { isMobile } from "react-device-detect";

//Question: should we use cache so that this page doesn't referesh each time?
function NftRanking() {
  const functions = getFunctions();
  //Question: what's a good way to set its type?
  const getResults = httpsCallable(functions, "getMostWinNfts");

  //battle data

  const [result, setResult] = useState<NftType[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [bottom, setBottom] = useState(false);

  const [lastNftUid, setLastNftUid] = useState<string>();

  useEffect(() => {
    const init = async () => {
      await getData();
    };
    setFirstLoading(true);
    init();
    setFirstLoading(false);
  }, []);

  //calling API endpoint to pull battle results
  const getData = async () => {
    const leng = result.length;
    const nftUid =
      //'0x000' is a dummy value that will not return any real NFT snapshot
      //used for the beginning
      //the backednd API "getmostWinNfts()" in nft.ts will handle
      leng === 0 ? "0x000" : result[leng - 1].uid;

    if (nftUid !== lastNftUid) {
      setLoading(true);
      const r: any = await getResults({
        number: 10,
        lastNftUid: nftUid,
      });
      const r2 = r.data.nfts;
      const r3 = [];
      for (let i = 0; i < r2.length; i++) {
        r3.push(r2[i].data);
      }
      setResult([...result, ...r3]);
      setLastNftUid(nftUid);

      setLoading(false);
      if (r3.length === 0) {
        setBottom(true);
      }
    } else {
      setBottom(true);
    }
  };

  const mobileView = (
    <div style={{ minHeight: "100vh" }}>
      {firstLoading ? (
        <LoadingView loadingItem={"NFT Rankings"} />
      ) : (
        <div className="container-fluid d-flex ">
          <div>
            {isMobile && <hr />}
            <h1
              className="text-center"
              style={{ fontWeight: "700", color: "#353840" }}
            >
              NFT Rankings
            </h1>

            <br></br>
            <table className=" table table-sm table-borderless border-primary align-middle text-center ">
              <thead>
                <tr>
                  <th style={{ fontWeight: "400" }} scope="col">
                    #
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Image
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Name
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Duels
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Win
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Draw
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Loss
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Score
                  </th>
                </tr>
              </thead>
              {result.map((r, i) => {
                return (
                  <tbody key={r.uid}>
                    {<MyNftRow rank={i + 1} nft={r}></MyNftRow>}
                  </tbody>
                );
              })}
            </table>
            {loading ? (
              <LoadingView loadingItem="NFT Rankings" />
            ) : bottom ? (
              <>
                <div className="d-flex justify-content-center">
                  <p className="text-muted"> You reached the Bottom </p>
                </div>
                <br></br>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" onClick={getData}>
                    Load More
                  </button>
                </div>
                <br></br>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const mobileView2 = (
    <div style={{ minHeight: "100vh" }}>
      {firstLoading ? (
        <LoadingView loadingItem={"NFT Rankings"} />
      ) : (
        <div className="container-fluid d-flex ">
          <div className="col-xs-0 col-sm-1 col-md-2"></div>
          <div className="col-xs-12 col-sm-10 col-md-8 ">
            {isMobile && <hr />}

            <h1
              className="text-center"
              style={{ fontWeight: "700", color: "#353840" }}
            >
              NFT Rankings
            </h1>

            <br></br>
            <table className=" table table-sm table-borderless border-primary align-middle text-center ">
              <thead>
                <tr>
                  <th style={{ fontWeight: "400" }} scope="col">
                    #
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Image
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Name
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Duels
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Win
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Draw
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Loss
                  </th>
                  <th style={{ fontWeight: "400" }} scope="col">
                    Score
                  </th>
                </tr>
              </thead>
              {result.map((r, i) => {
                return (
                  <tbody key={r.uid}>
                    {<MyNftRow rank={i + 1} nft={r}></MyNftRow>}
                  </tbody>
                );
              })}
            </table>
            {loading ? (
              <LoadingView loadingItem="NFT Rankings" />
            ) : bottom ? (
              <>
                <div className="d-flex justify-content-center">
                  <p className="text-muted"> You reached the Bottom </p>
                </div>
                <br></br>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-primary" onClick={getData}>
                    Load More
                  </button>
                </div>
                <br></br>
              </>
            )}
          </div>
          <div className="col-xs-0 col-sm-1 col-md-2"></div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        {firstLoading ? (
          <LoadingView loadingItem={"NFT Rankings"} />
        ) : (
          <div
            className={`container-fluid d-flex ${
              !isMobile && "justify-content-center"
            }`}
          >
            <div className="col-xs-0 col-sm-1 col-md-2"></div>
            <div className="col-xs-12 col-sm-10 col-md-8 ">
              {isMobile && <hr />}

              <h1
                className="text-center"
                style={{ fontWeight: "700", color: "#353840" }}
              >
                NFT Rankings
              </h1>

              <br></br>
              <table className=" table table-sm table-borderless border-primary align-middle text-center ">
                <thead>
                  <tr>
                    <th style={{ fontWeight: "400" }} scope="col">
                      #
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Image
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Name
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Duels
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Win
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Draw
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Loss
                    </th>
                    <th style={{ fontWeight: "400" }} scope="col">
                      Score
                    </th>
                  </tr>
                </thead>
                {result.map((r, i) => {
                  return (
                    <tbody key={r.uid}>
                      {<MyNftRow rank={i + 1} nft={r}></MyNftRow>}
                    </tbody>
                  );
                })}
              </table>
              {loading ? (
                <LoadingView loadingItem="NFT Rankings" />
              ) : bottom ? (
                <>
                  <div className="d-flex justify-content-center">
                    <p className="text-muted"> You reached the Bottom </p>
                  </div>
                  <br></br>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-primary" onClick={getData}>
                      Load More
                    </button>
                  </div>
                  <br></br>
                </>
              )}
            </div>
            <div className="col-xs-0 col-sm-1 col-md-2"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NftRanking;
