import { Nav, Navbar as NavbarBs, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ConnectMM2 from "../utility/Connect";
import { isMobile } from "react-device-detect";
import "./navstyle.css";

function Navbar() {
  return (
    <NavbarBs expand="lg">
      <div className="container">
        <div>
          <Nav.Link to="/battle" as={NavLink}>
            <NavbarBs.Brand>
              <img
                src="/imgs/battle.png"
                width="50"
                height="50"
                // className="d-inline-block align-top"
                alt="Duels"
              ></img>
            </NavbarBs.Brand>

            <NavbarBs.Brand
              style={{
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "48px",
              }}
            >
              <span>
                NFT Duels{" "}
                <span
                  style={{
                    color: "#2081E2",
                    fontSize: "15px",
                    verticalAlign: "super",
                  }}
                >
                  (BETA)
                </span>{" "}
              </span>
            </NavbarBs.Brand>
          </Nav.Link>
        </div>

        <NavbarBs.Toggle aria-controls="basic-navbar-nav" />
        <NavbarBs.Collapse id="basic-navbar-nav" className="px-auto">
          <Nav
            className="mx-auto"
            style={{
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            <Nav.Link
              to="/battle"
              as={NavLink}
              className=" text-underline-hover"
            >
              Play Duels&nbsp;&nbsp;&nbsp;&nbsp;
            </Nav.Link>
            <Nav.Link
              to="/nftranking"
              as={NavLink}
              className=" text-underline-hover"
            >
              Top NFTs&nbsp;&nbsp;&nbsp;&nbsp;
            </Nav.Link>
            {/* <Nav.Link
              to="/submitnft"
              as={NavLink}
              className="text-underline-hover"
            >
              &nbsp;&nbsp;Create Duels&nbsp;&nbsp;
            </Nav.Link> */}
            <Nav.Link
              to="/submitnft2"
              as={NavLink}
              className="text-underline-hover"
            >
              Create Duels&nbsp;&nbsp;&nbsp;&nbsp;
            </Nav.Link>
            <NavDropdown
              title="Results"
              id="basic-nav-dropdown"
              className="text-underline-hover"
            >
              <NavDropdown.Item to="/myvotes" as={NavLink}>
                My Voted Duels
              </NavDropdown.Item>
              <NavDropdown.Divider />

              <NavDropdown.Item to="/mybattles" as={NavLink}>
                My Created Duels
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="/stats" as={NavLink}>
                All Ended Duels
              </NavDropdown.Item>
            </NavDropdown>
            {!isMobile ? <div>&nbsp;&nbsp;&nbsp;&nbsp;</div> : ""}
            <Nav.Link
              to="/buytokens"
              as={NavLink}
              className="text-underline-hover"
            >
              Tokens&nbsp;&nbsp;&nbsp;&nbsp;
            </Nav.Link>
            <Nav.Link to="/faq" as={NavLink} className="text-underline-hover">
              FAQ&nbsp;&nbsp;
            </Nav.Link>
          </Nav>
          <NavbarBs.Text
            // style={{ backgroundColor: "green" }}
            className="mt-4"
          >
            <ConnectMM2 />
          </NavbarBs.Text>
        </NavbarBs.Collapse>
      </div>
      {/* </div> */}
    </NavbarBs>
  );
}
export default Navbar;
